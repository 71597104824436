import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "../../../assets/styles/search.scss";
import { SearchProvider } from "../../../contexts/SearchContext";
import { FilterBar } from "./FilterBar";
import SearchBar from "./SearchBar";
import {
  docSortBy,
  staticDateRanges,
  uploadedDocumentStatus,
} from "../../../constants";
import { optionsFromMapperObj } from "./helpers";
import { useReload } from "../../../hooks/useReload";
import { setDocumentSearch } from "../../../store/actions/search";
import { useMessageBoxError } from "../../../hooks/useMessageBox";
import { Results } from "./Results";
import { Divider } from "antd";

const statusOptions = optionsFromMapperObj(uploadedDocumentStatus);
const dateOptions = optionsFromMapperObj(staticDateRanges);
const sortOptions = optionsFromMapperObj(docSortBy);

/**
 * Search page - provides interface for find documents that matches search query
 *
 * @function
 *
 * @returns React JSX
 */
const Search = () => {
  const [entityTypes, setEntityTypes] = useState();
  const [docStatus, setDocStatus] = useState();
  const [dateRange, setDateRange] = useState("all_time");
  const [customDateRange, setCustomDateRange] = useState();
  const [sortBy, setSortBy] = useState("most_relevant");

  const { analysis, search } = useSelector((state) => state);
  const {
    getSearchRequest: { error, result },
    documents,
    entity_search_name,
  } = search;

  const dispatch = useDispatch();

  useMessageBoxError(error);
  useReload(result, () => dispatch(setDocumentSearch(result.data)));

  const typeOptions = Object.values(analysis.entityProps);

  useEffect(() => {
    setEntityTypes(typeOptions.map((t) => t.value));
    setDocStatus(statusOptions.map((s) => s.value));
  }, []);

  return (
    <SearchProvider
      value={{
        entityTypes,
        setEntityTypes,
        typeOptions,
        docStatus,
        setDocStatus,
        statusOptions,
        dateRange,
        setDateRange,
        dateOptions,
        customDateRange,
        setCustomDateRange,
        sortBy,
        setSortBy,
        sortOptions,
      }}
    >
      <div id="documents-search" className="documents-search">
        <div className="documents-search-app-card flex-1">
          <div className="documents-search-app-card-body">
            <section className="documents-search-searchBox">
              <SearchBar />
            </section>
            {Array.isArray(documents) && (
              <>
                <Divider className="documents-search-searchboxDivider" />
                {!documents.length && (
                  <>
                    <section className="documents-search-filterHead">
                      <FilterBar />
                      <div className="documents-search-noResults">
                        <h4 className="documents-search-noResults-title">
                          No results
                        </h4>
                        <p className="documents-search-noResults-text">
                          Read couldn’t find any reports related to your search.
                        </p>
                        <p className="documents-search-noResults-textMain">
                          You may want to try…
                        </p>
                        <ul className="documents-search-noResults-list">
                          <li className="documents-search-noResults-text">
                            Using different keywords
                          </li>
                          <li className="documents-search-noResults-text">
                            Checking for spaces
                          </li>
                          <li className="documents-search-noResults-text">
                            Adjusting your filters
                          </li>
                        </ul>
                      </div>
                    </section>
                  </>
                )}
              </>
            )}
            {Array.isArray(documents) && documents.length > 0 && (
              <>
                <section className="documents-search-filterHead">
                  <FilterBar />
                </section>
              </>
            )}
            <section>
              <Results documents={documents} entityName={entity_search_name} />
            </section>
          </div>
        </div>
      </div>
    </SearchProvider>
  );
};

export default Search;
