import "../../../../assets/styles/user.scss";
import EditPane from "./EditPane";

const Profile = () => {
  return (
    <>
      <div className="user-profile">
        <EditPane />
      </div>
    </>
  );
};

export default Profile;
