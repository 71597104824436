import { Button } from "antd";
import { documentStatus, reportStatusLabels } from "../../../../constants";
import api from "../../../../constants/api";
import useAxios from "../../../../hooks/useAxios";
import { useReloadAnalysis } from "../../../../hooks/useReload";
import { handleDocStatusUpdate } from "../../../../utils/helpers";
import { useMessageBoxError } from "../../../../hooks/useMessageBox";

const { PUT } = api.methods;
const { COMPLETED, REVIEWING } = documentStatus;

const StatusWidget = ({ docId, reportStatus }) => {
  const [{ result, error }, updateDocumentRequest] = useAxios(null, PUT);

  useReloadAnalysis(result);
  useMessageBoxError(error);

  if (reportStatus === COMPLETED) {
    return (
      <Button
        key="report-status-widget"
        shape="round"
        className="status-btn complete-btn bg-success fg-success cursor-pointer"
        size="small"
        onClick={() =>
          handleDocStatusUpdate(
            docId,
            REVIEWING,
            COMPLETED,
            updateDocumentRequest
          )
        }
      >
        <span className="current-status">
          {reportStatusLabels[reportStatus]}
        </span>
        <span className="next-status">Move to review</span>
      </Button>
    );
  }

  return (
    <Button
      key="report-status-widget"
      shape="round"
      className="status-btn bg-warning fg-warning cursor-pointer"
      size="small"
      onClick={() =>
        handleDocStatusUpdate(
          docId,
          COMPLETED,
          reportStatus,
          updateDocumentRequest
        )
      }
    >
      <span className="current-status">{reportStatusLabels[reportStatus]}</span>
      <span className="next-status">Mark as complete</span>
    </Button>
  );
};

export default StatusWidget;
