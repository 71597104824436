import { documentStatus } from "../../../constants";
import ActionTip from "../../common/widgets/ActionTip";
import useAxios from "../../../hooks/useAxios";
import WarningDialog from "../../common/widgets/WarningDialog";
import { handleDeleteDocument } from "../../../utils/helpers";
import { useRemoveDocument } from "../../../hooks/useDocumentsUpdate";
import DisplayTip from "../../common/widgets/DisplayTip";
import { useMessageBoxError } from "../../../hooks/useMessageBox";

import fileText from "../../../assets/svgs/entities/fileText.svg";
import loader from "../../../assets/svgs/entities/Queueloader.svg";
import Cross from "../../../assets/svgs/entities/x.svg";

const QueuedDocument = ({ document, index }) => {
  const { document_id, status, status_description, title } = document;

  const [{ result, error }, updateRequest] = useAxios(null, "PUT");

  useMessageBoxError(error);
  useRemoveDocument(result, document_id, documentStatus.QUEUE);

  const failed = status === documentStatus.NONE;

  return (
    <div className={`queued-document `}>
      <div className="queued-document-title">
        <>
          <img src={fileText} alt="Document-icon" className="file-icon" />
          <span className="queued-document-title-text">
            <DisplayTip title={status_description}>{title}</DisplayTip>
          </span>
        </>
      </div>
      <div className="queued-document-import">
        {!failed && (
          <>
            {status === "queued" ? "Queued" : "Importing"}
            <img src={loader} alt="loader" className="iconload" />
          </>
        )}
        {failed && (
          <WarningDialog
            title="Are you sure you want to remove this document?"
            onConfirm={() => handleDeleteDocument(document_id, updateRequest)}
            okText="Yes"
            cancelText="No"
            text="textConfirmation"
          >
            <ActionTip title="Remove from queue">
              <div type="text" size="small" className="queued-document-fail">
                Failed
                <img src={Cross} alt="cross" className="cross" />
              </div>
            </ActionTip>
          </WarningDialog>
        )}
      </div>
    </div>
  );
};

export default QueuedDocument;
