import { HIGHLIGTHED_NODE, INTERSET_NODE } from "../../constants";

/**
 * EntitiesTree class
 *
 * @class
 *
 * Defines methods for creating tree of entities data used for annotation
 */
class EntitiesTree {
  constructor(entities = []) {
    this._entitiesTree = [];
    this.total = entities.length;
    entities.sort((a, b) => a.start_point - b.start_point);
    this.entities = entities;
    this.processed = new Set();
    this.createTree();
  }

  getTree() {
    return this._entitiesTree;
  }

  createTree() {
    if (!this.total) return [];
    let entitiesTree = [];
    const processed = new Set();

    for (let i = 0; i < this.total; i++) {
      if (this.processed.has(i)) continue;
      const nodes = this._appendChildren(
        this._createEntityNode(i, this.entities[i]),
        i + 1,
        this.entities,
        processed
      );
      if (nodes) {
        Array.isArray(nodes)
          ? (entitiesTree = [...entitiesTree, ...nodes])
          : entitiesTree.push(nodes);
      }
    }

    this._entitiesTree = entitiesTree;
  }

  _createEntityNode(index) {
    const entity = this.entities[index];

    const {
      type,
      text: label,
      start_point: start,
      end_point: end,
      id,
      multiline_id,
      status,
    } = entity;

    return {
      idx: index,
      id,
      multiline_id,
      type,
      status,
      class: entity.class,
      block_idx: entity.block_idx,
      label,
      start,
      end,
      children: [],
    };
  }

  _appendChildren(node, nextIndex) {
    this.processed.add(node.idx);

    if (nextIndex >= this.total) return node;
    const nextNode = this._createEntityNode(nextIndex);

    const isAdjacent = node.end < nextNode.start;
    const isNested = node.end > nextNode.end;
    const isInterset = node.end > nextNode.start && node.end < nextNode.end;
    const isOverlay =
      node.start === nextNode.start && node.end === nextNode.end;

    if (isAdjacent) {
      return node;
    } else if (isNested) {
      node.children.push(this._appendChildren(nextNode, nextIndex + 1));
      for (let i = nextIndex + 1; i < this.total; i++) {
        if (this.processed.has(i)) continue;
        this._appendChildren(node, i);
      }

      return node;
    } else if (isInterset) {
      const diff = node.end - nextNode.start;

      let classes = `interset-node ${nextNode.type}`;
      if (
        node.type === HIGHLIGTHED_NODE ||
        nextNode.type === HIGHLIGTHED_NODE
      ) {
        classes += " interset-select";
      }

      // Set intersect node props
      const intersetNode = {
        type: INTERSET_NODE,
        left: node.type,
        right: nextNode.type,
        isInterset,
        iStart: nextNode.start,
        iEnd: node.end,
        start: nextNode.start,
        end: nextNode.end,
        id: nextNode.id,
        multiline_id: nextNode.multiline_id,
        classes,
        children: [],
      };

      // Set current node intersect props
      node.iStart = node.start;
      node.iEnd = node.end - diff;
      node.isInterset = true;
      node.classes = "interset-left";

      // Set nextNode intersect props
      nextNode.iStart = nextNode.start + diff;
      nextNode.iEnd = nextNode.end;
      nextNode.isInterset = true;
      nextNode.classes = "interset-right";

      this.processed.add(nextNode.idx);

      return [node, intersetNode, nextNode];
    } else if (isOverlay) {
      // Add nextNode to processed to exclude it from tree.
      // Removes duplicate annotated text
      this.processed.add(nextNode.idx);

      return node;
    } else {
      // Add node to processed set to exclude it from tree.
      // Removes duplicate annotated text
      this.processed.add(nextNode.idx);
      return node;
    }
  }
}

export default EntitiesTree;
