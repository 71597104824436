const actions = {
  SET_NODE_POSITION: "SET_NODE_POSITION",
  RESET_GRAPH_STATE: "RESET_GRAPH_STATE",
  DELETE_GRAPH: "DELETE_GRAPH",
};

export default actions;

export const setNodePosition = (graphId, nodeId, position) => {
  return {
    type: actions.SET_NODE_POSITION,
    payload: { graphId, nodeId, position },
  };
};
export const deleteGraph = (graphId) => ({
  type: actions.DELETE_GRAPH,
  payload: { graphId },
});

export const resetGraphState = () => ({
  type: actions.RESET_GRAPH_STATE,
});

export const getGraphById = (state, graphId) => state.graph[graphId] || {};
