import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { Dropdown, Menu } from "antd";
import ActionTip from "../../common/widgets/ActionTip";
import { handleEdit, handleDelete, handleGraph } from "./helpers";
import { useAuthContext } from "../../../contexts/AuthContext";
import { handleDownload } from "../../../utils/helpers";
import morehorizontal from "../../../assets/svgs/entities/morehorizontal.svg";

const DELETE_COLLECTION = "DELETE_COLLECTION";
const EDIT_COLLECTION = "EDIT_COLLECTION";
const COLLECTION_GRAPH = "COLLECTION_GRAPH";
const DOWNLOAD_COLLECTION = "DOWNLOAD_COLLECTION";
const DOWNLOAD_ATTACK_NAVIGATOR = "DOWNLOAD_ATTACK_NAVIGATOR";
const BASE_URL = window._env_.UNIFIED_SERVER_URL;

const MoreActions = ({ collectionId, documentCount }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { auth } = useAuthContext();

  const ttpsUrl = `${BASE_URL}/organisation/collections/${collectionId}/navigator_ttps`;
  const ttpsFileName = `collection_${collectionId}_navigator_ttps`;

  const bundleUrl = `${BASE_URL}/organisation/collections/${collectionId}/bundle/`;
  const bundleFileName = `collection_${collectionId}_bundle_::datetime::.json`;

  const menu = (
    <Menu
      key={collectionId}
      className="menu-dropdown-head p-0"
      style={{ width: "100%" }}
    >
      <Menu.Item
        key={EDIT_COLLECTION}
        onClick={() => handleEdit(collectionId, dispatch)}
        className="menu-item-rules"
      >
        Edit
      </Menu.Item>
      <Menu.Item
        key={COLLECTION_GRAPH}
        onClick={() => handleGraph(collectionId, history)}
        className="menu-item-rules"
      >
        Graph view
      </Menu.Item>
      <Menu.Item
        key={DOWNLOAD_COLLECTION}
        onClick={handleDownload(bundleUrl, bundleFileName, auth.token)}
        disabled={!documentCount}
        className="menu-item-rules"
      >
        Download JSON
      </Menu.Item>
      <Menu.Item
        key={DOWNLOAD_ATTACK_NAVIGATOR}
        onClick={handleDownload(ttpsUrl, ttpsFileName, auth.token)}
        className="menu-item-rules"
      >
        Download ATT&CK Navigator layer
      </Menu.Item>
      <Menu.Item
        key={DELETE_COLLECTION}
        onClick={() => handleDelete(collectionId, dispatch)}
        className="menu-item-rules delete"
      >
        <span className="menu-item-dropdown-delete">Delete</span>
      </Menu.Item>
    </Menu>
  );

  return (
    <>
      <Dropdown
        key={collectionId}
        onClick={(e) => e.stopPropagation()}
        overlay={menu}
        trigger={["click"]}
        destroyPopupOnHide={true}
      >
        <ActionTip title="More actions">
          <img
            src={morehorizontal}
            alt="more-actions"
            height={20}
            width={20}
            onClick={(e) => e.stopPropagation()}
          />
        </ActionTip>
      </Dropdown>
    </>
  );
};

export default MoreActions;
