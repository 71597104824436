import { Button } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import MoreActions from "./MoreActions";
import DisplayTip from "../../common/widgets/DisplayTip";
import WarningDialog from "../../common/widgets/WarningDialog";
import NavButton from "../../common/widgets/NavButton";
import routes from "../../../constants/routes";
import { showCollectionDocuments } from "./helpers";

const props = {
  buttonProps: { type: "link" },
  linkProps: {
    target: "_blank",
    rel: "noreferrer",
    onClick: undefined,
  },
};

export const collectionColumns = (dispatch, reloadCallback) => [
  {
    title: <span style={{ paddingLeft: "24px" }}>ID</span>,
    dataIndex: "id",
    key: "id",
    width: "140px",
    className: "title-column-id",
    render: (title) => (
      <span className="tag-style-value" style={{ maxWidth: "140px" }}>
        {title}
      </span>
    ),
  },
  {
    title: "Name",
    dataIndex: "title",
    key: "title",
    render: (title) => <span className="tag-style-value">{title}</span>,
  },
  {
    title: "Documents",
    dataIndex: "collected_documents",
    key: "collected_documents",
    align: "left",
    render: (collected_documents, collection) => (
      <Button
        type="link"
        onClick={() =>
          showCollectionDocuments(collection.id, dispatch, reloadCallback)
        }
        className="buckets-documents-list"
      >
        <span className="buckets-documents-list-item">
          {collected_documents} documents
        </span>
      </Button>
    ),
  },
  {
    title: "Description",
    dataIndex: "description",
    key: "description",
    ellipsis: {
      showTitle: false,
    },
    align: "left",
    render: (description) => (
      <DisplayTip placement="topLeft" title={description}>
        <span className="buckets-description-list-item">{description}</span>
      </DisplayTip>
    ),
  },
  {
    title: "",
    dataIndex: "id",
    align: "right",
    width: "303px",
    className: "title-column-more-actions-icon",
    render: (id, collection) => (
      <div style={{ textAlign: "right" }}>
        <Button type="text" size="small">
          <MoreActions
            collectionId={id}
            documentCount={collection?.collected_documents}
          />
        </Button>
      </div>
    ),
  },
];

export const documentCollectionsColumns = (removeDocument) => [
  {
    title: "Title",
    dataIndex: "title",
    key: "id",
    ellipsis: {
      showTitle: false,
    },
    render: (title, record) => (
      <DisplayTip placement="topLeft" title={record.description}>
        {title}
      </DisplayTip>
    ),
  },
  {
    title: "Action",
    key: "id",
    align: "right",
    render: (_, record) => (
      <WarningDialog
        title="Remove document from collection?"
        onConfirm={() => removeDocument(record.id)}
      >
        <Button type="text">
          <DeleteOutlined />
        </Button>
      </WarningDialog>
    ),
  },
];

export const collectionDocumentsColumns = (removeDocument) => [
  {
    title: "Title",
    dataIndex: "title",
    key: "document_id",
    ellipsis: {
      showTitle: false,
    },
    render: (title, record) => (
      <DisplayTip placement="topLeft" title={record.description}>
        {title}
      </DisplayTip>
    ),
  },
  {
    title: "Action",
    key: "document_id",
    align: "right",
    render: (_, { document_id }) => (
      <div className="justify-end">
        <NavButton
          url={routes.DOCUMENT_ANALYSIS.replace(":documentId", document_id)}
          type="link"
          text="Analysis"
          {...props}
        />
        <NavButton
          url={routes.STIX_GRAPH.replace(":documentId", document_id)}
          type="link"
          text="Graph"
          {...props}
        />
        <WarningDialog
          title="Remove document from collection?"
          onConfirm={() => removeDocument(document_id)}
        >
          <Button size="small" type="text">
            <DeleteOutlined />
          </Button>
        </WarningDialog>
      </div>
    ),
  },
];
