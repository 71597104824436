import { useDispatch, useSelector } from "react-redux";
import { analysisModes } from "../../../constants";
import { setAnalysisMode } from "../../../store/actions";
import ConfidenceScoreFilter from "./ConfidenceScoreFilter";
import { useState } from "react";

const { ANNOTATION, GRAPH, EXTRACTION_OVERVIEW, EXECUTIVE_SUMMARY } =
  analysisModes;

/**
 * AnalysisPanel
 *
 * @returns React JSX
 */
const AnalysisPanel = ({ children }) => {
  const {
    analysis: { analysisMode, fullSummary },
  } = useSelector((state) => state);
  const dispatch = useDispatch();

  const [overview, setOverview] = useState(false);

  const handleModeChange = (mode) => {
    setOverview(false);
    dispatch(setAnalysisMode(mode));
  };

  return (
    <div className="analysis-panel main-header-AnalysisPanel">
      {!overview && (
        <>
          <div
            className="section-card"
            style={{ zIndex: 1, border: "1px solid #EAECF0" }}
          >
            <div
              className="section-card-body justify-between"
              style={{ border: "none" }}
            >
              <div className="section-card-body-btns">
                {fullSummary && (
                  <div
                    className={`section-card-body-btns-text ${
                      analysisMode === EXECUTIVE_SUMMARY && "highlight"
                    }`}
                    onClick={() => {
                      handleModeChange(EXECUTIVE_SUMMARY);
                    }}
                  >
                    Summary
                  </div>
                )}
                <div
                  className={`section-card-body-btns-text ${
                    analysisMode === ANNOTATION && "highlight"
                  }`}
                  onClick={() => {
                    handleModeChange(ANNOTATION);
                  }}
                >
                  Annotation
                </div>
                <div
                  className={`section-card-body-btns-text ${
                    analysisMode === GRAPH && "highlight"
                  }`}
                  onClick={() => {
                    handleModeChange(GRAPH);
                  }}
                >
                  Graph
                </div>
                <div
                  className={`section-card-body-btns-text ${
                    analysisMode === EXTRACTION_OVERVIEW && "highlight"
                  }`}
                  onClick={() => handleModeChange(EXTRACTION_OVERVIEW)}
                >
                  Extraction Overview
                </div>
              </div>
              {analysisMode === ANNOTATION && <ConfidenceScoreFilter />}
            </div>
          </div>
          <div className="full-height">{children}</div>
        </>
      )}
    </div>
  );
};

export default AnalysisPanel;
