import { Table } from "antd";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useReload } from "../../../hooks/useReload";
import { removeSlideInView } from "../../../store/actions";
import {
  getAllCollectionsRequest,
  setCollections,
} from "../../../store/actions/collections";
import { collectionColumns } from "./columns";
import { Pagination } from "../rulesManager/Pagination";

const CollectionsTable = () => {
  const dispatch = useDispatch();
  const {
    collectionList,
    getSingleCollection: g,
    getAllCollections: gac,
    updateCollection: u,
    createCollection: c,
    deleteCollection: d,
  } = useSelector(({ collections }) => collections);
  const [pagination, setpagination] = useState({
    page: 1,
    length: 10,
    per_page: 10,
  });
  const reloadCollections = () => dispatch(getAllCollectionsRequest());

  useReload(g.result, reloadCollections);
  useReload(u.result, reloadCollections);
  useReload(c.result, reloadCollections);
  useReload(d.result, reloadCollections);
  useReload(gac.result, () => dispatch(setCollections(gac.result.data)));
  useReload(u.result, () => dispatch(removeSlideInView()));
  useReload(c.result, () => dispatch(removeSlideInView()));
  useEffect(() => dispatch(getAllCollectionsRequest()), []);

  const rowClassName = (record, index) => {
    return index % 2 === 0 ? "table-even-row" : "table-odd-row";
  };
  const handlePaginationChange = (page, pageSize) => {
    setpagination((prev) => ({
      ...prev,
      page,
      per_page: pageSize,
    }));
  };

  return (
    <>
      <div className="left-sidebar-options-panel-mainBody mt-30">
        <Table
          rowKey={"id"}
          dataSource={collectionList.slice(
            (pagination.page - 1) * pagination.per_page,
            pagination.page * pagination.per_page
          )}
          columns={collectionColumns(dispatch, reloadCollections)}
          rowClassName={rowClassName}
          className="buckets-table-collection"
          pagination={false}
        />
        {collectionList.length !== 0 && (
          <Pagination
            current={pagination.page}
            total={Math.ceil(collectionList.length / pagination.per_page)}
            pageSize={pagination.per_page}
            handlepagniation={handlePaginationChange}
          />
        )}
      </div>
    </>
  );
};

export default CollectionsTable;
