import { Button } from "antd";

export const Pagination = ({ total, current, handlepagniation, pageSize }) => {
  return (
    <>
      <div
        className="pagination-container"
        style={{ backgroundColor: "#FFFFFF" }}
      >
        <div className="pagination-container-content">
          Page{" "}
          <span>
            {current} of {total}
          </span>
        </div>
        <div className="pagination-container-content">
          <Button
            className="pagination-container-content-btn-prev"
            onClick={() => handlepagniation(current - 1, pageSize)}
            disabled={current - 1 < 1}
          >
            Previous
          </Button>
          <Button
            className="pagination-container-content-btn-nxt"
            onClick={() => handlepagniation(current + 1, pageSize)}
            disabled={total < current + 1}
          >
            Next
          </Button>
        </div>
      </div>
    </>
  );
};
