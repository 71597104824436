import { createContext, Fragment, useContext, useState } from "react";
import { CloseCircleOutlined } from "@ant-design/icons";
import {
  TEXT_ENTITY_CLASS,
  HIGHLIGTHED_NODE,
  RELATED_NODE,
  SELECTION_MARKER,
  TEXT_PARENT,
  PROCESSING,
  entityStatus,
  TEXT_SELECTION_MARKER,
  HIGHLIGTHED_PLAIN_NODE,
} from "../../../constants";
import TextNode from "../../common/nodes/TextNode";
import ContextOptions from "./ContextOptions";
import { useSelector } from "react-redux";
import { Tooltip } from "antd";

const specialNodes = [HIGHLIGTHED_NODE, HIGHLIGTHED_PLAIN_NODE];

export const AnnotationContext = createContext();

function TextAnnotation(props) {
  const { text, entity, position, processingEntities } = props;

  return (
    <AnnotationContext.Provider value={props}>
      <Nested
        processingEntities={processingEntities}
        text={text}
        entity={entity}
        position={position}
        nodetype={TEXT_PARENT}
      />
    </AnnotationContext.Provider>
  );
}

export default TextAnnotation;

export function useAnnotationContext() {
  const context = useContext(AnnotationContext);
  if (!context) {
    throw new Error(
      `Annotation compound components cannot be rendered outside the Annotation component`
    );
  }
  return context;
}

function Node({ text, index, entityType = "" }) {
  return (
    <TextNode index={index} className={entityType} type={TEXT_PARENT}>
      {text}
    </TextNode>
  );
}

const EntityTooltipStatus = ({ status }) => {
  const statusText =
    status === entityStatus.ACCEPTED ? "Accepted" : "Unconfirmed";
  return (
    <>
      <span>{statusText}</span>
    </>
  );
};

export function Nested({
  text,
  entity,
  position,
  nodetype,
  processingEntities = {},
}) {
  const { textData = {} } = useAnnotationContext();
  const {
    relationship: { entityRelations },
  } = useSelector((state) => state);
  const {
    type,
    status,
    label,
    id,
    isInterset,
    classes = "",
    multiline_id,
  } = entity;
  const meta = JSON.stringify({
    start: entity.start,
    end: entity.end,
    blockId: textData.blockId,
    isInterset,
    type,
    status,
    // label,
    id,
    multiline_id,
    class: entity.class,
    block_idx: entity.block_idx,
  });

  const relatedNode = entityRelations[`${entity.id}`] ? RELATED_NODE : "";
  const processing = processingEntities[id] ? PROCESSING : "";
  const [hovered, setHovered] = useState(false);

  return (
    <Fragment>
      {!specialNodes.includes(type) && (
        <Fragment>
          <Tooltip
            title={<EntityTooltipStatus status={status} />}
            color="#101828"
            trigger="hover"
            overlayClassName="entity-status-tooltip"
            visible={hovered}
            onVisibleChange={(open) => {
              setHovered(open);
            }}
            onMouseEnter={(e) => {
              const classValues = e.target.classList.value;
              if (
                classValues.includes("popover") ||
                classValues.includes("ant-menu-submenu")
              ) {
                setTimeout(() => {
                  setHovered(false);
                }, 200);
              }
            }}
          >
            <TextNode
              id={`entity-${multiline_id || id}`}
              index={position}
              className={`${TEXT_ENTITY_CLASS} ${type} ${classes} ${relatedNode} ${processing} `}
              type={nodetype}
              meta={meta}
            >
              <InnerNodes
                text={text}
                entity={entity}
                position={position}
                nodetype={nodetype}
              />
              <span className="remove-annotation" onClick={() => {}}>
                <CloseCircleOutlined />
              </span>
            </TextNode>
          </Tooltip>
        </Fragment>
      )}
      {type === HIGHLIGTHED_NODE && (
        <HighlightedNode meta={meta}>
          <InnerNodes
            text={text}
            entity={entity}
            position={position}
            nodetype={nodetype}
          />
        </HighlightedNode>
      )}
      {type === HIGHLIGTHED_PLAIN_NODE && (
        <HighlightedPlainNode meta={meta}>
          <InnerNodes
            text={text}
            entity={entity}
            position={position}
            nodetype={nodetype}
          />
        </HighlightedPlainNode>
      )}
    </Fragment>
  );
}

const InnerNodes = ({ text, entity, position, nodetype }) => {
  const childrenCount = Array.isArray(entity.children)
    ? entity.children.length
    : 0;

  return (
    <Fragment>
      {childrenCount === 0 &&
        text.slice(entity.iStart || entity.start, entity.iEnd || entity.end)}
      {childrenCount > 0 && (
        <Fragment>
          {text.slice(
            entity.iStart || entity.start,
            entity.children[0].iStart || entity.children[0].start
          )}
          {entity.children.map((child, idx) => (
            <Fragment key={idx}>
              <Nested
                text={text}
                entity={child}
                position={`${position}-${idx}`}
                nodetype={`${nodetype}-${position}-${idx}`}
              />
              {idx < childrenCount - 1 &&
                text.slice(
                  child.iEnd || child.end,
                  entity.children[idx + 1].iStart ||
                    entity.children[idx + 1].start
                )}
            </Fragment>
          ))}
          {text.slice(
            entity.children[childrenCount - 1].iEnd ||
              entity.children[childrenCount - 1].end,
            entity.iEnd || entity.end
          )}
        </Fragment>
      )}
    </Fragment>
  );
};

const HighlightedNode = ({ children, meta }) => {
  const { block } = useAnnotationContext();
  return (
    <Fragment>
      <ContextOptions block={block}>
        <mark
          id={TEXT_SELECTION_MARKER}
          data-index={SELECTION_MARKER}
          data-nodetype={SELECTION_MARKER}
          data-meta={meta}
        >
          {children}
        </mark>
      </ContextOptions>
    </Fragment>
  );
};

const HighlightedPlainNode = ({ children, meta }) => {
  return (
    <Fragment>
      <mark
        class={TEXT_SELECTION_MARKER}
        data-index={SELECTION_MARKER}
        data-nodetype={SELECTION_MARKER}
        data-meta={meta}
      >
        {children}
      </mark>
    </Fragment>
  );
};

export function Adjacent() {
  const { text, entity, position } = useAnnotationContext();
  return (
    <Node
      index={position}
      text={text.slice(entity.start, entity.end)}
      entityType={entity.type}
    />
  );
}
