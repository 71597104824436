import { Modal } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import React, { useState } from "react";
import FeaturedIcon from "../../../assets/svgs/entities/Featured-icon.svg";

const WarningDialog = ({
  title,
  onConfirm,
  okText = "Delete",
  cancelText = "Cancel",
  closeDropdown,
  children,
  text,
}) => {
  const [isVisible, setIsVisible] = useState(false);

  const handleOk = () => {
    onConfirm();
    if (closeDropdown) {
      closeDropdown();
    }
    setIsVisible(false);
  };

  const handleCancel = () => {
    if (closeDropdown) {
      closeDropdown();
    }
    setIsVisible(false);
  };

  return (
    <>
      <span onClick={() => setIsVisible(true)}>{children}</span>
      <Modal
        title={null}
        visible={isVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
        closable={false}
        className="delete-button-modal"
        modalClassName="modal-background"
      >
        <div className="DeleteDocument-modal">
          <img
            src={FeaturedIcon}
            alt="featuredIcon"
            className="DeleteDocument-featured-icon"
          />
          <h2 className="DeleteDocument-heading-title">{title}</h2>
          {text && (
            <p className="DeleteDocument-paragraph">
              Are you sure you want to remove this document?
              <span className="DeleteDocument-paragraph-span">
                This action cannot be undone.
              </span>
            </p>
          )}
          <div className="DeleteDocument-button-head">
            <button
              onClick={handleCancel}
              className="DeleteDocument-button-cancelText"
            >
              {cancelText}
            </button>
            <button onClick={handleOk} className="DeleteDocument-button-okText">
              {okText}
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default WarningDialog;

export const showModalConfirm = (options = {}) => {
  Modal.confirm({
    title: "Do you really want to perform this action?",
    icon: <ExclamationCircleOutlined />,
    content: "",
    okText: "Yes",
    cancelText: "No",
    onOk() {
      console.log("OK");
    },
    onCancel() {
      console.log("Cancel");
    },
    ...options,
  });
};
