import { Modal } from "antd";

import {
  TEXT_ENTITY_CLASS,
  COMMON_PARENT,
  WRAPPER_PARENT,
  PROCESSING,
} from "../../../constants";
import { setSelection } from "../../../store/actions";
import { completeRelationshipProcess } from "./relationship";
import { getNewRelationshipConfirmOptions } from "../../messages/popup/relationship";
import { selectionError } from "../../../constants/error";
import errorMessages from "../../messages/errorMessages";
import TextSelection from "../../services/TextSelection";

export const textSelectionHelper = (
  event,
  newRelation,
  documentId,
  dispatch
) => {
  // Prevent triple or more click selection
  if (event.detail >= 3 && window.getSelection) {
    return window.getSelection().removeAllRanges();
  }

  let commonParent = null;
  let blockId = "";
  let selectedTextEntity = null;
  let selectedSentenceEntity = null;
  let selectedEntity = null;
  let isMultipleEntities = false; // tracks if multiple entities are detected on current selection.
  let treatSingle = false; // treats as single node wether multiple is detected or not.
  const target = event.target;

  try {
    // Stop operation if the target node is already being processed e.g virtual entities
    if (target.classList.contains(PROCESSING))
      return Modal.warn({ title: errorMessages.entity_processing });

    // Get data about a text entity node
    if (target.classList.contains(TEXT_ENTITY_CLASS)) {
      if (target.dataset.meta)
        selectedTextEntity = JSON.parse(target.dataset.meta);

      if (target.dataset.single) {
        treatSingle = !!target.dataset.single;
        target.dataset.single = "";
      }
    }

    // Get commonParent element.
    // Do nothing if selection is outside a common parent
    if (
      target === event.currentTarget ||
      target.dataset.type === WRAPPER_PARENT ||
      !!target.closest(".remove-annotation")
    ) {
      return;
    } else if (target.dataset.type === COMMON_PARENT) {
      commonParent = target;
    } else {
      commonParent = target.closest(`.${COMMON_PARENT}`);
    }

    // Set block id of the selected context or entity
    if (commonParent) blockId = commonParent.parentElement.id;

    if (commonParent && commonParent.dataset.meta) {
      if (commonParent.classList.contains(PROCESSING))
        return Modal.warn({ title: errorMessages.entity_processing });
      selectedSentenceEntity = treatSingle
        ? undefined
        : JSON.parse(commonParent.dataset.meta);
      if (selectedSentenceEntity) {
        treatSingle = !!commonParent.dataset.single;
        commonParent.dataset.single = "";
        // Set an id that will be used to reference the relevant dom element if selected
        selectedSentenceEntity.commonId = commonParent.id;
      }
    }

    isMultipleEntities =
      !!(selectedTextEntity && selectedSentenceEntity) ||
      !!(selectedSentenceEntity && selectedSentenceEntity.ids.length > 1);
    selectedEntity = selectedTextEntity || selectedSentenceEntity;

    // Add new relationship if it was already initiated and a single entity is selected
    if (
      newRelation.started &&
      (treatSingle || !isMultipleEntities) &&
      !!selectedEntity
    ) {
      return completeRelationshipProcess(
        selectedEntity,
        documentId,
        newRelation,
        dispatch
      );
    }

    // Show dialog if a new relation was in progress
    // but selected node is not an entity node
    if (newRelation.started && !selectedEntity)
      return Modal.confirm(getNewRelationshipConfirmOptions(dispatch));

    // Set and get relevant selection properties
    const textSelection = new TextSelection();
    textSelection.setProps(commonParent);
    const props = textSelection.getProps();

    props.blockId = blockId || props.blockId;
    props.selectedTextEntity = selectedTextEntity;
    props.selectedSentenceEntity = selectedSentenceEntity;
    props.startedNewRelationship = newRelation.started;
    props.timestamp = Date.now();

    dispatch(setSelection(props));

    // Clear selection ranges
    textSelection.selection.empty();
  } catch (error) {
    // console.log(error);
    if (Object.values(selectionError).includes(error.message)) {
      Modal.warn({ title: error.message });
    }
  }
};
