import { Button } from "antd";
import ActionTip from "./ActionTip";
import Eye from "../../../assets/svgs/entities/eye.svg";
import Eyeoff from "../../../assets/svgs/entities/eye-off.svg";

const MaskButton = ({
  mask = false,
  maskText = "Mask",
  unMaskText = "Unmask",
  clickHandler = () => {},
}) => {
  return mask ? (
    <ActionTip title={unMaskText}>
      <Button
        size="small"
        type="text"
        onClick={() => clickHandler(false)}
        className="mask-button"
      >
        <img src={Eye} alt="eye-icon" height={20} width={20} />
      </Button>
    </ActionTip>
  ) : (
    <ActionTip title={maskText}>
      <Button
        size="small"
        type="text"
        onClick={() => clickHandler(true)}
        className="mask-button"
      >
        <img src={Eyeoff} alt="eye-icon" height={20} width={20} />
      </Button>
    </ActionTip>
  );
};

export default MaskButton;
