import { Card } from "antd";
import ApexCharts from "apexcharts";
import "../../../assets/styles/performance-dashboard.scss";
import { useEffect, useRef } from "react";
import { barChartOptions } from "./chartOptions";

const OverviewCard = ({
  title,
  data,
  dateRanges,
  extraInfo = null,
  optionHandler,
}) => {
  const chartRef = useRef();

  const allowedTitles = ["CONFIDENCE OVERVIEW", "ANNOTATION OVERVIEW"];
  const legendColors = {
    "CONFIDENCE OVERVIEW": {
      almost_certain: "#7F56D9",
      very_likely: "#B692F6",
      probable: "#E9D7FE",
    },
    "ANNOTATION OVERVIEW": {
      accepted: "#12B76A",
      rejected: "#F63D68",
      changed: "#D5D9EB",
    },
    "TIME SAVED": {
      manual_annotation: "rgb(242, 245, 247)",
      read_annotation: "rgb(224, 222, 253)",
    },
  };

  useEffect(() => {
    let options = barChartOptions;
    if (data && optionHandler) options = optionHandler(data, dateRanges);
    const chart = new ApexCharts(chartRef.current, {
      ...options,
      tooltip: {
        ...options.tooltip,
        custom: function (args) {
          return options.tooltip.custom({ ...args, data });
        },
      },
      data,
    });
    chart.render();

    return () => chart.destroy();
  }, [data]);

  return (
    <div>
      <Card className="report-card">
        <div className="titleWithLegend">
          <span className="overview-section-card">{title}</span>

          {data && legendColors[title] && allowedTitles.includes(title) && (
            <div className="custom-legend">
              {data.map((item) => (
                <span
                  key={item.category}
                  className="legend-item"
                  style={{ color: legendColors[title][item.category] }}
                >
                  ●<span className="legend-text">{item.label}</span>
                </span>
              ))}
            </div>
          )}
          {extraInfo}
        </div>
        <div
          className="apex-charts"
          style={{ color: "#667085" }}
          ref={chartRef}
        ></div>
      </Card>
    </div>
  );
};

export default OverviewCard;
