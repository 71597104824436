import { Button, Menu, Dropdown } from "antd";
import {
  DownOutlined,
  SettingOutlined,
  MinusCircleOutlined,
  CheckCircleFilled,
} from "@ant-design/icons";
import { TLP, OVERRIDES, MIXED } from "../../../../constants";
import { showSlideInView } from "../../../../store/actions";
import {
  addDocumentTLPRequest,
  getDocumentTLP,
} from "../../../../store/actions/apiRequests";
import DocumentTLP from "./DocumentTLP";
import { useDispatch, useSelector } from "react-redux";
import { useReload } from "../../../../hooks/useReload";
import { useEffect } from "react";
import { tlpStyle } from "./handlers";
import { useMessageBoxError } from "../../../../hooks/useMessageBox";
import { ReactComponent as Tlp } from "../../../../assets/svgs/entities/Tlp.svg";

const TLPWidget = ({ docId }) => {
  const dispatch = useDispatch();
  const { analysisApi } = useSelector((state) => state);
  const { documentTlp, addDocumentTlp, deleteDocumentTlp } = analysisApi;

  useMessageBoxError(addDocumentTlp.error);
  useMessageBoxError(deleteDocumentTlp.error);
  useMessageBoxError(documentTlp.error);
  useReload(addDocumentTlp.result, () => dispatch(getDocumentTLP(docId)));
  useReload(deleteDocumentTlp.result, () => dispatch(getDocumentTLP(docId)));

  useEffect(() => {
    if (docId) dispatch(getDocumentTLP(docId));
  }, [docId]);

  const tlpData = documentTlp.result?.data;
  const tlpCount = (tlpData?.entity_groups || []).length;
  const tlpLevel = tlpData?.document_tlp;

  return (
    <Dropdown
      key="tlp-key"
      overlay={getTlpMenu(tlpLevel, tlpCount, docId, dispatch)}
      trigger={["click"]}
    >
      <Button size="small" className="title-btn tlp-btn">
        <Tlp />
        {!tlpLevel && !tlpCount ? (
          "No TLP"
        ) : (
          <>
            TLP:
            <span style={tlpStyle(tlpLevel)}>
              {tlpCount ? MIXED : tlpLevel}
            </span>
          </>
        )}
      </Button>
    </Dropdown>
  );
};

const getTlpMenu = (documentTlpLevel, tlpCount, documentId, dispatch) => {
  const updateTLP = ({ key }) => {
    if (key === OVERRIDES) {
      return dispatch(
        showSlideInView(
          DocumentTLP,
          { documentId },
          { title: "Document TLP Levels" }
        )
      );
    }

    const payload = { document_tlp: key, entity_groups: [] };

    dispatch(addDocumentTLPRequest(documentId, payload));
  };

  return (
    <Menu onClick={updateTLP}>
      {Object.values(TLP).map((tlpLevel) => (
        <Menu.Item key={tlpLevel}>
          TLP:
          <span style={tlpStyle(tlpLevel)}>
            {tlpLevel} {tlpLevel === documentTlpLevel && <CheckCircleFilled />}
          </span>
        </Menu.Item>
      ))}
      <Menu.Divider />
      {!!documentTlpLevel && (
        <Menu.Item key="" icon={<MinusCircleOutlined />}>
          Unset Default TLP
        </Menu.Item>
      )}
      <Menu.Item key={OVERRIDES} icon={<SettingOutlined />}>
        {OVERRIDES} ({tlpCount})
      </Menu.Item>
    </Menu>
  );
};

export default TLPWidget;
