import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { init } from "stixview";
import { Menu, Dropdown, Button, Switch, Radio, Empty } from "antd";
import {
  DownOutlined,
  DownloadOutlined,
  RadarChartOutlined,
} from "@ant-design/icons";
import { useReload } from "../../../hooks/useReload";
import { getDocumentExtendedStixBundle } from "../../../store/actions/stixGraph";
import Mixpanel from "../../../utils/services/Mixpanel";
import { useErrorPage } from "../../../hooks/useErrorPage";
import events from "../../../constants/analyticEvents";
import {
  exportStixGraphImage,
  handleClickGraphNode,
  runGraphLayout,
  toggleReportNode,
} from "./helpers";
import { useAnalysisData } from "../../../hooks/useAnalysisData";
import AppModal from "../../common/modals/AppModal";
import infoMessages from "../../../utils/messages/infoMessages";
import SecondEntityForRelationship from "../analysis/SecondEntityForRelationship";
import {
  NEW_RELATIONSHIP_RESET,
  removeSlideInView,
} from "../../../store/actions";
import { batch } from "react-redux";
import { getAnalysisDetails } from "../../../store/actions/apiRequests";
import {
  useMessageBoxError,
  useMessageBoxSuccess,
} from "../../../hooks/useMessageBox";
import {
  getGraphById,
  setNodePosition,
} from "../../../store/actions/graphActions";

const layouts = ["cola", "klay", "cose-bilkent", "cise", "grid", "dagre"];

const GraphPane = ({ docId, showOptions = true }) => {
  const [exportType, setExportType] = useState("jpg");
  const [entityIds, setEntityIds] = useState([]);
  const [showRelationshipModal, setShowRelationshipModal] = useState(false);
  const [reportNode, setReportNode] = useState(true);
  const [isEmptySTIX, setIsEmptySTIX] = useState(false);
  const graphHolderRef = useRef(null);
  const graphRef = useRef(null);
  const wrapperRef = useRef();
  const entityGroupRef = useRef({});
  const newRelationRef = useRef({});
  const reportCollRef = useRef([]);
  const dispatch = useDispatch();
  const cy = graphRef.current?.cy;
  const [layout, setLayout] = useState(layouts[0]);
  const [showLabels, setShowLabels] = useState(true);
  const [showTlpAsTags, setShowTlpAsTags] = useState(false);
  const [newrelation, setnewrelation] = useState(false);
  const { stixGraph, analysis, analysisApi, relationship } = useSelector(
    (state) => state
  );
  const graph = useSelector((state) => getGraphById(state, docId));

  entityGroupRef.current = analysis.entityGroups;
  newRelationRef.current = relationship.newRelation;

  const {
    getStixJson: { result, error },
  } = stixGraph;
  const document = analysis.document;
  const {
    analysisDetails,
    addRelationship,
    rejectBulkEntities,
    acceptBulkEntities,
    deleteRelationship,
  } = analysisApi;
  const tlpData = analysisApi.documentTlp.result?.data;

  useErrorPage(error, [403, 404, 500]);
  useErrorPage(analysisDetails.error, [403, 404, 500]);
  useAnalysisData(analysisDetails.result.data);
  useMessageBoxError(error);
  useMessageBoxSuccess(rejectBulkEntities.result);
  useMessageBoxError(rejectBulkEntities.error);
  useMessageBoxSuccess(acceptBulkEntities.result);
  useMessageBoxError(acceptBulkEntities.error);
  useMessageBoxSuccess(addRelationship.result);
  useMessageBoxError(addRelationship.error);
  useReload(acceptBulkEntities.result, () => dispatch(removeSlideInView()));
  useReload(rejectBulkEntities.result, () => {
    batch(() => {
      dispatch(removeSlideInView());
      dispatch(getAnalysisDetails(docId));
      dispatch(getDocumentExtendedStixBundle(docId));
    });
  });
  useReload(addRelationship.result, () => {
    dispatch(getDocumentExtendedStixBundle(docId));
  });
  useReload(deleteRelationship.result, () => {
    dispatch(getDocumentExtendedStixBundle(docId));
  });

  useReload(result, () => {
    if (graphRef.current && result.data.stix?.id)
      graphRef.current.loadData(result.data.stix);
    else if (!result.data.stix?.id) {
      setIsEmptySTIX(true);
    }
  });
  useEffect(() => {
    if (cy && graph && Object.keys(graph).length > 0) {
      Object.keys(graph).forEach((id) => {
        const node = cy.getElementById(id);

        if (node) {
          node.position(graph[id]);
        }
      });
    }

    setnewrelation(false);
  }, [cy, graph, newrelation]);

  // Analytic data
  const analyticEventProps = {
    bundleId: result.data?.id,
    documentId: document.document_id,
    documentTitle: document.title,
    graphLayout: layout,
    graphAreaWidth: wrapperRef.current?.offsetWidth,
    graphAreaHeight: wrapperRef.current?.offsetHeight,
  };
  const handleNodeDrag = (nodeId, position) => {
    dispatch(setNodePosition(docId, nodeId, position));
  };

  useLayoutEffect(() => {
    try {
      dispatch(getDocumentExtendedStixBundle(docId));
      const holder = graphHolderRef.current;
      let wrapperHeight = wrapperRef.current.offsetHeight - 50;
      if (wrapperHeight < 600) wrapperHeight = 600;

      graphRef.current = init(
        holder,
        null,
        (g) => {
          Mixpanel.track(events.STIX_GRAPH_LOADED, analyticEventProps);
          toggleReportNode(reportNode, setReportNode, reportCollRef, g.cy);
          g.cy.on("load", () => {
            if (graph && Object.keys(graph).length > 0) {
              Object.keys(graph).forEach((id) => {
                const node = g.cy.getElementById(id);
                if (node) {
                  node.position(graph[id]);
                }
              });
            }
          });
          g.cy.nodes().forEach((node) => {
            const nodeId = node.id();
            const position = node.position();
            if (!graph[nodeId]) {
              handleNodeDrag(nodeId, position);
            }
          });
          g.cy.on("add", "edge", (event) => {
            setnewrelation(true);
          });
          g.cy.on("free", "node", (event) => {
            const node = event.target;

            handleNodeDrag(node.id(), node.position());
            node.position({ x: node.position().x, y: node.position().y });
          });

          if (graph && Object.keys(graph).length > 0) {
            Object.keys(graph).forEach((id) => {
              const node = g.cy.getElementById(id);
              if (node) {
                node.position(graph[id]);
              }
            });
          }

          return () => {
            g.cy.off("free", "node");
            g.cy.off("load");
            g.cy.off("add", "edge");
          };
        },
        {},
        {
          graphHeight: wrapperHeight,
          hideFooter: true,
          showSidebar: false,
          onClickNode: handleClickGraphNode(
            entityGroupRef,
            newRelationRef,
            setEntityIds,
            setShowRelationshipModal,
            dispatch
          ),
        }
      );
    } catch (error) {}
  }, []);
  const menu = (
    <Menu
      onClick={runGraphLayout(
        graphRef.current,
        layout,
        analyticEventProps,
        setLayout
      )}
    >
      {layouts.map((l) => (
        <Menu.Item key={l}>{l.toUpperCase()}</Menu.Item>
      ))}
    </Menu>
  );

  return (
    <div ref={wrapperRef} className="b-white full-height">
      {showOptions && !isEmptySTIX && (
        <div className="justify-between">
          <div className="px-10 py-10 align-center">
            <Dropdown overlay={menu} trigger={["click"]}>
              <Button type="text">
                {"Layout >>"} {layout.toUpperCase()} <DownOutlined />
              </Button>
            </Dropdown>
            <div className="py-5 px-10">
              <span> Labels </span>
              <Switch
                className="mb-2"
                size="small"
                checked={showLabels}
                onChange={(checked) => {
                  setShowLabels(checked);
                  graphRef.current.toggleLabels(checked);
                }}
              />
            </div>
            <div className="py-5 px-10">
              <span> TLP </span>
              <Switch
                className="mb-2"
                size="small"
                checked={showTlpAsTags}
                disabled={!tlpData?.document_tlp || !tlpData?.entity_groups}
                onChange={(checked) => {
                  setShowTlpAsTags(checked);
                  graphRef.current.reloadData({ showTlpAsTags: checked });
                }}
              />
            </div>
            <div className="py-5 px-10">
              <span> Report Node </span>
              <Switch
                className="mb-2"
                size="small"
                checked={reportNode}
                onChange={() => {
                  toggleReportNode(
                    reportNode,
                    setReportNode,
                    reportCollRef,
                    cy
                  );
                }}
              />
            </div>
          </div>
          <div className="px-10 py-10 align-center">
            <Dropdown
              overlay={
                <Menu
                  onClick={exportStixGraphImage(cy, exportType, document.title)}
                >
                  <Menu.Item key="full">Full Graph</Menu.Item>
                  <Menu.Item key="current">Current View</Menu.Item>
                </Menu>
              }
              trigger={["click"]}
            >
              <Button type="text">
                Export <DownloadOutlined />
              </Button>
            </Dropdown>
            <Radio.Group
              value={exportType}
              onChange={(e) => setExportType(e.target.value)}
            >
              <Radio value="png">PNG</Radio>
              <Radio value="jpg">JPG</Radio>
            </Radio.Group>
          </div>
        </div>
      )}
      {!isEmptySTIX && (
        <div
          className="bd-0 mt-0 pt-0 b-white"
          ref={graphHolderRef}
          data-stix-allow-dragdrop="false"
          data-show-idrefs="false"
          data-show-marking-nodes="false"
          data-show-sidebar="false"
          data-graph-layout={layout}
          data-enable-mouse-zoom="true"
          data-show-tlp-as-tags={showTlpAsTags}
          data-show-labels={showLabels}
          data-show-footer="false"
        ></div>
      )}
      {isEmptySTIX && (
        <div className="py-30">
          <Empty
            image={
              <RadarChartOutlined style={{ fontSize: 72, color: "#ABABAB" }} />
            }
            description={<h2>No STIX Data found</h2>}
          />
        </div>
      )}
      <AppModal
        title={infoMessages.complete_new_relationship}
        visible={showRelationshipModal}
        onOk={() => setShowRelationshipModal(false)}
        onCancel={() => setShowRelationshipModal(false)}
        footer={null}
      >
        <SecondEntityForRelationship
          entityIds={entityIds}
          handleCancle={() => {
            dispatch({ type: NEW_RELATIONSHIP_RESET });
            setShowRelationshipModal(false);
          }}
        />
      </AppModal>
    </div>
  );
};

export default GraphPane;
