import {
  COMMON_PARENT,
  entityClass,
  entityScope,
  HIGHLIGTHED_NODE,
  PROCESSING,
  RELATED_NODE,
  SENTENCE_ENTITY_CLASS,
} from "../../../constants";

import ContextOptions from "./ContextOptions";
import EntitiesTree from "../../../utils/services/EntitiesTree";
import Annotation from "../../../utils/services/Annotation";
import { useSelector } from "react-redux";
import { makeVEPrefix } from "../../../utils/helpers/analysis/entities";

/**
 * HTMLConstruct - Construct html block and include annotionaton, selections and options
 *
 * @function
 *
 * @param {{object}} block - The block data
 * @param {object} activeSelection properties of the current selection
 * @returns React JSX
 */
const HTMLConstruct = ({ block, activeSelection, highlighted = [] }) => {
  const { analysis, selection, relationship } = useSelector((state) => state);
  const { entitiesByIds } = analysis;
  const { entityRelations } = relationship;

  const { index, text = "", text_entities_ids, sentence_entities_ids } = block;
  const { isClick, selectedTextEntity } = activeSelection || {};
  let textEntities = text_entities_ids.map(
    (entityId) => entitiesByIds[entityId]
  );

  const inlinePrefix = makeVEPrefix(entityScope.INLINE, index);
  const virtualInlineEntities = selection.virtualEntities[inlinePrefix];
  if (virtualInlineEntities) {
    textEntities = [...textEntities, ...Object.values(virtualInlineEntities)];
  }

  textEntities = [...textEntities, ...highlighted];
  let entities = [];

  if (activeSelection && (!isClick || selectedTextEntity)) {
    const { selectedText, absoluteStart, absoluteEnd } = activeSelection;
    entities = [
      ...textEntities,
      {
        id: 0,
        type: HIGHLIGTHED_NODE,
        text: selectedText,
        start_point: absoluteStart,
        end_point: absoluteEnd,
      },
    ];
  } else {
    entities = textEntities;
  }

  const entitiesTree = new EntitiesTree(entities);
  let textClass = `analysis-sentence ${COMMON_PARENT}`;
  let sentenceMeta = "";
  let domId;
  const blockPrefix = makeVEPrefix(entityScope.BLOCK, index);
  const virtualBlockEntities = selection.virtualEntities[blockPrefix];
  let blockEntity;
  if (virtualBlockEntities) {
    blockEntity = Object.values(virtualBlockEntities)[0];
  }
  if (sentence_entities_ids.length || blockEntity) {
    if (!blockEntity) blockEntity = entitiesByIds[sentence_entities_ids[0]];

    const id = blockEntity.id;
    const type = blockEntity.type;
    const status = blockEntity.status;
    const sentenceIndex = blockEntity.sentence_idx;
    const tids = [];
    sentence_entities_ids.forEach((entityId) => {
      tids.push(entitiesByIds[entityId].sub_properties.id);
    });
    sentenceMeta = JSON.stringify({
      id,
      type,
      start: 0,
      end: text.length - 1,
      ids: sentence_entities_ids,
      status,
      class: blockEntity.class,
      block_idx: blockEntity.block_idx,
      tids,
    });

    textClass += " " + SENTENCE_ENTITY_CLASS;
    textClass += selection.processingEntities[id] ? " " + PROCESSING : "";
    domId = `entity-${entityClass.SENTENCE}-${sentenceIndex}`;

    for (let id of sentence_entities_ids) {
      if (entityRelations[id]) {
        textClass += " " + RELATED_NODE;
        break;
      }
    }
  }

  if (activeSelection && isClick && !selectedTextEntity) {
    return (
      <ContextOptions block={block}>
        <span
          id={domId}
          data-type={COMMON_PARENT}
          className={textClass}
          data-meta={sentenceMeta}
        >
          {Annotation.constructNodes(
            entitiesTree,
            block,
            selection.processingEntities
          )}
        </span>
      </ContextOptions>
    );
  } else {
    return (
      <span
        id={domId}
        data-type={COMMON_PARENT}
        className={textClass}
        data-meta={sentenceMeta}
      >
        {Annotation.constructNodes(
          entitiesTree,
          block,
          selection.processingEntities
        )}
      </span>
    );
  }
};

export default HTMLConstruct;
