export const formatDate = (dateString) => {
  if (!dateString) return "";
  const [_, m, d, y] = new Date(dateString).toDateString().split(" ");
  return m;
};

const getDateCategories = (d) =>
  d.map(([start_date, end_date]) => {
    const startMonth = formatDate(start_date);
    const endMonth = formatDate(end_date);
    return startMonth === endMonth ? startMonth : endMonth;
  });

export const barChartOptions = {
  series: [],
  chart: {
    type: "bar",
    height: 274,
    stacked: true,
    stackType: "100%",
    toolbar: {
      show: false,
    },
  },
  tooltip: {
    enabled: true,
    theme: "light",
    custom: function ({ series, seriesIndex, dataPointIndex, w }) {
      const apiData = w.config.series.map((s, index) => ({
        category: w.config.data?.[index]?.category,
        label: w.config.data?.[index]?.label,
      }));

      const categoryInfo = apiData[seriesIndex] || {};
      const month = w.globals.labels[dataPointIndex];
      const value = series[seriesIndex][dataPointIndex];
      const percentage = ((value / 480) * 100).toFixed(1);

      return `<div class="custom-tooltip">
          <span class="tooltip-month">${month}</span>
          <span class="tooltip-value">${
            categoryInfo.label || "N/A"
          }: ${value} (${percentage}%)</span>
        </div>`;
    },
  },
  responsive: [
    {
      breakpoint: 480,
    },
  ],
  plotOptions: {
    bar: {
      horizontal: false,
      columnWidth: "16px",
      padding: {
        left: 12,
        right: 12,
        top: 0,
        bottom: 0,
      },
    },
  },
  xaxis: {
    type: "category",
    categories: [],
    labels: {
      show: true,
      rotate: 0,
      style: {
        fontSize: "10px",
        color: "#667085",
      },
    },
    axisTicks: {
      show: false,
    },
    axisBorder: {
      show: true,
      color: "transparent",
    },
  },
  yaxis: {
    min: 0,
    max: 100,
    tickAmount: 5,
    labels: {
      formatter: function (val) {
        return `${val}%`;
      },
    },
  },
  grid: {
    show: true,
    borderColor: "#F2F4F7",
  },
  legend: {
    show: false,
  },
  stroke: {
    show: true,
    curve: "smooth",
    lineCap: "butt",
    colors: undefined,
    width: 2,
    dashArray: 0,
  },
  noData: {
    text: "No data",
  },
};

export const getConfScoreOptions = (data = [], dateRanges = []) => {
  const series = data.map((d) => {
    d.items.map((item) => item.count);
    return {
      name: d.label,
      data: d.items.map((item) => item.count),
    };
  });

  return {
    ...barChartOptions,
    series,
    colors: ["#7F56D9", "#B692F6", "#E9D7FE"],
    xaxis: {
      ...barChartOptions.xaxis,
      categories: getDateCategories(dateRanges),
    },
    stroke: {
      ...barChartOptions.stroke,
      colors: ["#7F56D9", "#B692F6", "#E9D7FE"],
    },
    dataLabels: {
      enabled: false,
    },
  };
};

export const getAnnotationOptions = (data = [], dateRanges = []) => {
  const series = data.map((d) => {
    d.items.map((item) => item.count);
    return {
      name: d.label,
      data: d.items.map((item) => item.count),
    };
  });

  return {
    ...barChartOptions,
    series,
    colors: ["#12B76A", "#F63D68", "#D5D9EB"],
    xaxis: {
      ...barChartOptions.xaxis,
      categories: getDateCategories(dateRanges),
    },
    stroke: {
      ...barChartOptions.stroke,
      colors: ["#12B76A", "#F63D68", "#D5D9EB"],
    },
    dataLabels: {
      enabled: false,
    },
  };
};

export const getTimeSavedChartOptions = (data = [[], []], dateRanges = []) => {
  const [manual_time, automated_time] = data;
  const series = [
    {
      name: "Manual annotation time",
      data: manual_time.map((m) => (m.duration / 60).toFixed(1)),
    },
    {
      name: "Read annotation time",
      data: automated_time.map((a) => (a.duration / 60).toFixed(1)),
    },
  ];

  return {
    ...barChartOptions,
    colors: ["#F2F5F7", "#E0DEFD"],
    series,
    chart: {
      ...barChartOptions.chart,
      stacked: false,
      stackType: undefined,
    },
    xaxis: {
      categories: getDateCategories(dateRanges),
    },
    yaxis: {
      title: {
        text: "Minutes",
      },
    },
    stroke: {
      ...barChartOptions.stroke,
      colors: ["#C3CFD9", "#6558F5"],
    },
    dataLabels: {
      enabled: false,
    },
  };
};
