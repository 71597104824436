import React from "react";
import { Modal } from "antd";
import ImportPane from "../../pages/documents/ImportPane";

const ImportDocumentModal = ({ isVisible, handleOk, handleCancel }) => {
  const overlayStyle = {
    backgroundColor: "rgba(0, 0, 0, 0.8)",
  };

  return (
    <Modal
      className="modal-main-top"
      visible={isVisible}
      onCancel={handleCancel}
      onOk={handleOk}
      footer={null}
      closable={false}
      maskStyle={overlayStyle}
      style={{ borderRadius: "12px" }}
    >
      <ImportPane handleCancel={handleCancel} />
    </Modal>
  );
};

export default ImportDocumentModal;
