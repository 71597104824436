import axios from "axios";
// import store from "../../store";
import { getQueryParams } from ".";
import { promiseTypeDefault, promiseTypeSuffixes } from "../../constants";
import { addPageLoader, removePageLoader } from "../../store/actions";
import errorMessages from "../messages/errorMessages";
import infoMessages from "../messages/infoMessages";

const [LOADING, SUCCESS, FAILED] = promiseTypeSuffixes;

/**
 * Helper for normalizing api error
 *
 * @function
 * @param {object} err Error object
 * @returns error object
 */
export const normalizeAPIError = (err, store = null) => {
  let responseData;
  let reqId;

  if (err.response && err.response.data) responseData = err.response.data;
  if (err.config && err.config.url)
    reqId = getQueryParams(err.config.url)["reqId"];

  let errorObj = { message: errorMessages.went_wrong, reqId };

  if (responseData && responseData.error) {
    errorObj = { ...errorObj, ...responseData.error };
  } else if (responseData) {
    errorObj = { ...errorObj, ...responseData };
  } else if (err.isAxiosError) {
    errorObj = { ...errorObj, message: err.message, clientError: true };
  } else if (axios.isCancel(err)) {
    errorObj = null;
    if (store) store.dispatch(removePageLoader());
  }

  return errorObj;
};

/**
 * Helper for normalizing api result
 *
 * @function
 * @param {object} responseData API response data
 * @returns normalized api result.
 */
export const normalizeAPIResult = (responseData) => {
  let result = responseData;

  if (result.success === undefined) {
    result = {
      success: true,
      message: result.message || infoMessages.request_successful,
      data: responseData,
    };
  }

  return result;
};

/**
 * Middleware for normalizing error in the failed stage
 *
 * @function
 * @returns next middleware in the list
 */
export const errorMiddleware = (store) => {
  return (next) => (action) => {
    try {
      if (action.type.endsWith(FAILED))
        action.payload = normalizeAPIError(action.payload, store);
      return next(action);
    } catch (error) {}
  };
};

/**
 * Middleware for configuring api state in different stages - loading, success, failed
 *
 * @function
 * @param {object} store Redux store
 * @returns next middleware in the list
 */
export const apiStateMiddleware = (store) => {
  return (next) => (action) => {
    try {
      if (action.type.endsWith(LOADING)) {
        store.dispatch(addPageLoader());
        const nextAction = {
          ...action,
          payload: { ...promiseTypeDefault, loading: true },
        };
        return next(nextAction);
      }
      if (action.type.endsWith(SUCCESS)) {
        const nextAction = {
          ...action,
          payload: {
            ...promiseTypeDefault,
            reqId: getQueryParams(action.payload.config.url)["reqId"],
            result: normalizeAPIResult(action.payload.data),
          },
        };
        store.dispatch(removePageLoader());
        return next(nextAction);
      }
      if (action.type.endsWith(FAILED)) {
        const { reqId, ...errorPayload } = action.payload;
        const nextAction = {
          ...action,
          payload: {
            ...promiseTypeDefault,
            error: errorPayload,
            reqId,
          },
        };
        store.dispatch(removePageLoader());
        return next(nextAction);
      }

      return next(action);
    } catch (error) {}
  };
};

/**
 * Initialize API reducer state
 *
 * @function
 * @param {object} actionTypes type/typeState pairs
 * @returns {object} initial state
 */
export const initializeState = (actionTypes) => {
  const init = {};
  Object.values(actionTypes).forEach((typeState) => {
    init[typeState] = promiseTypeDefault;
  });

  return init;
};

/**
 * Generates action types for API reducers
 *
 * @function
 * @param {object} actionTypes type/typeState pairs
 * @returns {object} apiActionTypes
 */
export const generateApiActionTypes = (actionTypes) => {
  const apiActionTypes = {};
  for (let type in actionTypes) {
    apiActionTypes[`${type}_${LOADING}`] = actionTypes[type];
    apiActionTypes[`${type}_${SUCCESS}`] = actionTypes[type];
    apiActionTypes[`${type}_${FAILED}`] = actionTypes[type];
  }

  return apiActionTypes;
};
