import { useEffect, useState } from "react";
import { Button, Checkbox, List, Radio, Typography } from "antd";

const { Title } = Typography;

export const OptionList = ({
  title,
  options,
  singleSelect = false,
  noEmptySelection = false,
  defaultSingle,
  footer,
  minWidth,
  onSelection = () => {},
}) => {
  const [value, setValue] = useState();
  const [values, setValues] = useState(new Set());

  const handleChange = (value) => () => {
    const v = new Set(values);
    if (v.has(value)) {
      if (noEmptySelection && v.size <= 1) return;
      v.delete(value);
    } else {
      v.add(value);
    }
    setValues(v);
  };

  useEffect(() => {
    if (singleSelect) setValue(defaultSingle);
    else {
      setValues(new Set(options.map(({ value }) => value)));
    }
  }, []);

  useEffect(() => {
    onSelection(Array.from(values));
  }, [values]);

  useEffect(() => {
    onSelection(value);
  }, [value]);

  return (
    <>
      {!singleSelect && (
        <List
          size="small"
          header={
            <div
              className="justify-between performance-dropdown-header"
              style={{ minWidth: minWidth || "200px" }}
            >
              <Title level={4}>{title}</Title>
              <Button
                type="text"
                onClick={() =>
                  setValues(new Set(options.map(({ value }) => value)))
                }
              >
                <Title level={5} type="secondary" underline>
                  Select all
                </Title>
              </Button>
            </div>
          }
          dataSource={options}
          renderItem={(item) => (
            <List.Item className="dropdown-options">
              <Checkbox
                onChange={handleChange(item.value)}
                checked={values.has(item.value)}
              >
                {item.label}
              </Checkbox>
            </List.Item>
          )}
        />
      )}
      {singleSelect && (
        <List
          size="small"
          header={
            <div
              className="justify-between  performance-dropdown-header"
              style={{ minWidth: minWidth || "200px" }}
            >
              <Title level={4}>{title}</Title>
            </div>
          }
          dataSource={options}
          renderItem={(item) => (
            <List.Item className="dropdown-options">
              <Radio
                checked={value === item.value}
                value={item.value}
                onClick={(e) => setValue(e.target.value)}
              >
                {item.label}
              </Radio>
            </List.Item>
          )}
          footer={footer}
        />
      )}
    </>
  );
};
export default OptionList;
