import attackPattern from "../assets/svgs/entities/attack_pattern.svg";
import indicator from "../assets/svgs/entities/indicator.svg";
import malware from "../assets/svgs/entities/malware.svg";
import tool from "../assets/svgs/entities/tool.svg";
import threatActor from "../assets/svgs/entities/threat_actor.svg";
import vulnerability from "../assets/svgs/entities/vulnerability.svg";
import identity from "../assets/svgs/entities/identity_class.svg";
// import campaign from "../assets/svgs/entities/campaign.svg";
import campaign from "../assets/images/Campaign_round.png";
import location from "../assets/svgs/entities/location.svg";
import intrusionSet from "../assets/svgs/entities/intrusion_set.svg";
import courseOfAction from "../assets/svgs/entities/course_of_action.svg";
import infrastructure from "../assets/svgs/entities/infrastructure.svg";

export const entitySvgs = {
  "attack-pattern": attackPattern,
  "threat-actor": threatActor,
  "intrusion-set": intrusionSet,
  "course-of-action": courseOfAction,
  indicator,
  malware,
  tool,
  vulnerability,
  identity,
  campaign,
  location,
  infrastructure,
};
