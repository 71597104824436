import SelectInput from "../../../common/forms/SelectInput";
import countries from "../../../../data/countries";

/**
 * Country search dropdown
 *
 * @returns React JSX
 */
const SelectCountry = ({
  setSelectedCountryCode,
  autoFocus = false,
  defaultOpen = true,
  onClick = undefined,
}) => {
  return (
    <>
      <span
        className="selecttid"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <div className="selecttid-select">
          <SelectInput
            style={{ width: "100%" }}
            autoFocus={autoFocus}
            defaultOpen={defaultOpen}
            placeholder="Search country"
            notFoundContent="Not Found"
            onClick={onClick}
            onChange={(countryCode) => setSelectedCountryCode(countryCode)}
            options={Object.entries(countries).map(
              ([countryCode, countryName]) => ({
                value: countryCode,
                label: `[${countryCode}] ${countryName}`,
              })
            )}
          />
        </div>
      </span>
    </>
  );
};

export default SelectCountry;
