import { useEffect, useState } from "react";
import { Form, Input, Button, Menu } from "antd";
import _ from "lodash";
import { useAuthContext } from "../../../../contexts/AuthContext";
import useAxios from "../../../../hooks/useAxios";
import CopyButton from "../../../common/widgets/CopyButton";
import MaskButton from "../../../common/widgets/MaskButton";
import WarningDialog from "../../../common/widgets/WarningDialog";
import api from "../../../../constants/api";
import { useReload } from "../../../../hooks/useReload";
import { maskText } from "../../../../utils/helpers";
import Auth from "../../../../utils/services/Auth";
import { useSelector } from "react-redux";
import { useMessageBoxError } from "../../../../hooks/useMessageBox";
import { ReactComponent as UserProfile } from "../../../../assets/svgs/entities/userProfile.svg";
import { ReactComponent as Terminal } from "../../../../assets/svgs/entities/terminal.svg";
import UserAvatar from "../../../common/widgets/UserAvatar";
import { USER_PROFILE, USER_APIS } from "../../../../constants";

const UNIFIED_URL = window._env_.UNIFIED_SERVER_URL;

const EditPane = () => {
  const { auth } = useAuthContext();
  const userDetails = useSelector(({ user }) => user.details);

  const [userInfo, setUserInfo] = useState({});
  const [originalInfo, setOriginalInfo] = useState(null);
  const [apiKeyMask, setApiKeyMask] = useState(true);
  const [activeTab, setActiveTab] = useState(USER_PROFILE);

  const [{ loading: updating, result: usif, statusCode }, submitUserInfo] =
    useAxios(null, "POST");

  const [{ result: apiRes, error: apiKeyErr }, generateAPIKeyRequest] =
    useAxios(null, api.methods.POST);

  const [{ result: keyRes }, getApiKeyRequest] = useAxios();

  useEffect(() => getApiKeyRequest(UNIFIED_URL + api.API_KEY), []);

  useEffect(() => {
    const { email, firstName, lastName, title } = userDetails;
    setUserInfo({ email, firstName, lastName, title });
  }, [userDetails]);

  useEffect(() => {
    if (statusCode >= 200 && statusCode <= 204) {
      (async () => {
        await Auth.authenticate();
      })();
    }
  }, [statusCode, usif]);

  useReload(apiRes, () => getApiKeyRequest(UNIFIED_URL + api.API_KEY));
  useMessageBoxError(apiKeyErr);

  const handleInputChange = (e) => {
    const value = e.target.value;
    let copied = null;
    if (!originalInfo) copied = _.cloneDeep(userInfo);
    setUserInfo((info) => ({
      ...info,
      [e.target.id]: value,
    }));
    if (copied) setOriginalInfo(copied);
  };

  const handleSubmitInfo = () => {
    const payload = {
      firstName: userInfo.firstName,
      lastName: userInfo.lastName,
      attributes: {
        user_title: userInfo.title,
      },
    };
    submitUserInfo(auth.createAccountUrl(), payload);
  };

  const keyData = keyRes.data || {};
  const maskOptions = { showPrefix: 2, showSuffix: 3 };
  const maskedApiKey = maskText(keyData.api_key, maskOptions);

  return (
    <>
      <div>
        <div className="left-sidebar-options-panel">
          <div className="left-sidebar-options-panel-mainHead">
            <div className="left-sidebar-options-panel-Header">
              <h2 className="left-sidebar-options-panel-Heading">
                Account settings
              </h2>
            </div>

            <div className="profile-sidebar">
              <Menu
                className="profile-sidebar-head"
                onClick={(e) => setActiveTab(e.key)}
                selectedKeys={[activeTab]}
              >
                <Menu.Item
                  className="profile-sidebar-options"
                  key={USER_PROFILE}
                >
                  <UserProfile
                    height={16}
                    width={16}
                    className="profile-sidebar-options-icon"
                  />
                  <span className="justify-between profile-sidebar-options-value">
                    {USER_PROFILE}
                  </span>
                </Menu.Item>

                <Menu.Item key={USER_APIS} className="profile-sidebar-options">
                  <Terminal
                    height={16}
                    width={16}
                    className="profile-sidebar-options-icon"
                  />
                  <span className="justify-between profile-sidebar-options-value">
                    {USER_APIS}
                  </span>
                </Menu.Item>
              </Menu>
            </div>
            <div className="profile">
              {activeTab === USER_PROFILE ? (
                <div>
                  <div className="profile-head">
                    <div className="profile-head-info">Your avatar</div>
                    <UserAvatar
                      name={`${userDetails.firstName} ${userDetails.lastName}`}
                      size={64}
                      icon={undefined}
                      style={{
                        fontSize: 24,
                        background: "#EAECF0",
                        color: "#000000",
                        fontWeight: 500,
                        lineHight: 32,
                      }}
                      data-testid="user-initials"
                    />
                  </div>

                  <div className="">
                    <div className="profile-head-info">Your Info</div>
                    <div className="profile-holder">
                      <Form layout="vertical" className="profile-holder-form">
                        <Form.Item
                          label="Email address"
                          className="profile-holder-form-label"
                        >
                          <Input
                            value={userInfo.email}
                            className="profile-holder-form-label-input"
                            placeholder="Your email"
                            disabled
                          />
                        </Form.Item>
                        <Form.Item
                          label="First name"
                          className="profile-holder-form-label"
                        >
                          <Input
                            id="firstName"
                            value={userInfo.firstName}
                            className="profile-holder-form-label-input"
                            placeholder="Your given name"
                            onChange={handleInputChange}
                          />
                        </Form.Item>
                        <Form.Item
                          label="Last name"
                          className="profile-holder-form-label"
                        >
                          <Input
                            id="lastName"
                            value={userInfo.lastName}
                            className="profile-holder-form-label-input"
                            placeholder="Your family name"
                            onChange={handleInputChange}
                          />
                        </Form.Item>
                        <Form.Item
                          label="Title"
                          className="profile-holder-form-label"
                        >
                          <Input
                            id="title"
                            value={userInfo.title}
                            className="profile-holder-form-label-input"
                            placeholder="Your title e.g 'Analyst'"
                            onChange={handleInputChange}
                          />
                        </Form.Item>
                        <Form.Item className="mb-0">
                          <Button
                            type="primary"
                            disabled={!originalInfo}
                            onClick={handleSubmitInfo}
                            className="profile-holder-form-label-button"
                          >
                            Save changes
                          </Button>
                        </Form.Item>
                      </Form>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="profile-holder">
                  <Form layout="vertical" className="profile-holder-form">
                    <Form.Item
                      label="API Key"
                      className="profile-holder-form-label"
                    >
                      <Input
                        id="apiKey"
                        value={apiKeyMask ? maskedApiKey : keyData.api_key}
                        className="profile-holder-form-label-input"
                        placeholder="API Key"
                        disabled
                        addonBefore={
                          <MaskButton
                            mask={apiKeyMask}
                            clickHandler={setApiKeyMask}
                          />
                        }
                        addonAfter={<CopyButton text={keyData.api_key} />}
                      />
                      <div>
                        <WarningDialog
                          title={
                            <>
                              A new API key will be generated while <br />
                              the current one will become invalid. <br />
                              <br />
                              Is this what you want?
                            </>
                          }
                          onConfirm={() => {
                            generateAPIKeyRequest(UNIFIED_URL + api.API_KEY);
                          }}
                          okText="Yes"
                          cancelText="No"
                        >
                          <Button type="link" className="profile-apibutton">
                            Generate a new key
                          </Button>
                        </WarningDialog>
                      </div>
                    </Form.Item>
                  </Form>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditPane;
