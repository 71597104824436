import { useState } from "react";
import { Button } from "antd";
import { copyTextClipBoard } from "../../../utils/helpers";
import ActionTip from "./ActionTip";
import Copy from "../../../assets/svgs/entities/copy.svg";

const CopyButton = ({
  text,
  successDisplay = "Copied",
  duration = 1000,
  inputId = "",
}) => {
  const [copied, setCopied] = useState(false);

  const isCopy = () => {
    setCopied(true);
    setTimeout(() => setCopied(false), duration);
  };

  return (
    <ActionTip title="Copy to clipboard">
      <Button
        size="small"
        type="text"
        onClick={() => copyTextClipBoard(text, inputId, isCopy)}
        className="copy-button"
      >
        {copied ? (
          successDisplay
        ) : (
          <img src={Copy} alt="copy-button" height={16} width={16} />
        )}
      </Button>
    </ActionTip>
  );
};

export default CopyButton;
