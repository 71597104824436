import { Layout } from "antd";
import { useSelector } from "react-redux";
import {
  appendHiddenLineStyle,
  positionLines,
  scrollingIntervals,
} from "../../../utils/helpers/analysis/relationship";
import CenterSpinLoader from "../loaders/CenterSpinLoader";
import ModalView from "../widgets/ModalView";
import SlideInView from "../widgets/SlideInView";
import Footer from "./Footer";
import Header from "./Header";
import { DocumentProvider } from "../../../contexts/DocumentContext";
import { documentStatus, documentFilters } from "../../../constants";
import { useState } from "react";
/**
 * AppLayout
 * wraps the layout components
 */

const defaultDocs = {
  [documentStatus.FOR_REVIEW]: [],
  [documentStatus.REVIEWING]: [],
  [documentStatus.COMPLETED]: [],
  [documentStatus.QUEUE]: [],
  [documentFilters.SEARCH]: "",
  [documentFilters.IMPORTED_BY]: "",
  [documentFilters.COLLECTION]: "",
  [documentFilters.ACCESS]: "",
  [documentStatus.FOR_REVIEW + "_count"]: "",
  [documentStatus.REVIEWING + "_count"]: "",
  [documentStatus.COMPLETED + "_count"]: "",
};
const AppLayout = ({ children }) => {
  const { relationship, loader } = useSelector((state) => state);
  const [documentData, setDocumentData] = useState(defaultDocs);

  return (
    <Layout className="app-layout">
      <DocumentProvider value={{ documentData, setDocumentData }}>
        <Header loader={loader} />
        <div className="app-body relative-p">
          <Layout.Content
            onScroll={scrollingIntervals(
              appendHiddenLineStyle,
              positionLines(relationship.props)
            )}
            className="app-content"
          >
            {children}
          </Layout.Content>
          <SlideInView />
          <ModalView />
          <Footer />
        </div>
      </DocumentProvider>
      <CenterSpinLoader loading={loader.loading} />
    </Layout>
  );
};

export default AppLayout;
