import { useCallback, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import ContentLoader from "../../common/widgets/ContentLoader";
import {
  appendHiddenLineStyle,
  scrollingIntervals,
  createRelationshipProps,
} from "../../../utils/helpers/analysis/relationship";
import { useAnalysisContext } from "../../../contexts/AnalysisContext";
import HTMLBlock from "./HTMLBlock";
import { textSelectionHelper } from "../../../utils/helpers/analysis/selection";
import SkeletonLoader from "../../common/loaders/SkeletonLoader";
import { ANALYSIS_BLOCKS, SHOW_ALL_RELATIONSHIPS } from "../../../constants";

const scrollEvent = new Event("scroll");
const resizeEvent = new Event("resize");
const virtualBlockStyle = (start) => ({
  position: "absolute",
  top: 0,
  left: 0,
  width: "100%",
  transform: `translateY(${start}px)`,
});

/**
 * DocumentAnnotation - Renders a document analysis - entities, relationships, sentences, tables, images etc
 *
 * @function
 * @returns React JSX
 */
const DocumentAnnotation = ({ parentRef, virtualItems }) => {
  const {
    analysis,
    relationship,
    analysisApi: { analysisDetails },
  } = useSelector((state) => state);
  const { relations, visibility } = relationship;
  const { entitiesByIds, blockGroups } = analysis;

  const { initialLoading } = useAnalysisContext();

  const relationsRef = useRef(null);
  const entitiesRef = useRef(null);

  const dispatch = useDispatch();

  useEffect(() => {
    relationsRef.current = relationship;
    entitiesRef.current = entitiesByIds;
  }, [relationship]);

  const handleScroll = useCallback(
    scrollingIntervals(appendHiddenLineStyle, () => {
      if (visibility === SHOW_ALL_RELATIONSHIPS)
        createRelationshipProps(relationsRef, entitiesRef, dispatch);
    }),
    [visibility]
  );

  useEffect(() => {
    if (visibility === SHOW_ALL_RELATIONSHIPS)
      createRelationshipProps(relationsRef, entitiesRef, dispatch);
  }, [visibility, dispatch]);

  /* Mouse up event handler for text selection */
  const handleTextSelection = (e) => {
    textSelectionHelper(
      e,
      relationship.newRelation,
      analysis.document.id,
      dispatch
    );
  };

  useEffect(() => {
    if (analysis.document.id) return;
    window.dispatchEvent(resizeEvent);
  }, [analysis.document.id]);

  /* Add and remove event listener on component mount and unmount respectively */
  useEffect(() => {
    if (!parentRef) return;
    const contentNode = parentRef.current;
    contentNode.addEventListener("click", handleTextSelection);

    return () => {
      if (!contentNode) return;
      contentNode.removeEventListener("click", handleTextSelection);
    };
  }, [relationship.newRelation]);

  /* Position relationship lines on scroll and resize */
  useEffect(() => {
    parentRef.current.dispatchEvent(scrollEvent);
    window.addEventListener("resize", handleScroll);
    return () => window.removeEventListener("resize", handleScroll);
  }, [relations]);

  const contentStyle = relationship.newRelation.started
    ? { cursor: "alias" }
    : { cursor: "auto" };

  const isLagging = !analysis.document.id && !analysisDetails.error;

  return (
    <div
      ref={parentRef}
      onScroll={handleScroll}
      className="analysis-annotation annoation-mainHead-box"
      style={contentStyle}
    >
      <SkeletonLoader />
      {(initialLoading || isLagging) && (
        <ContentLoader repeat={5} size="large" />
      )}
      <div
        id={ANALYSIS_BLOCKS}
        style={{ height: "100%", width: "100%", position: "relative" }}
      >
        {virtualItems.map(({ index, start, measureRef }) => {
          return (
            <div key={index} ref={measureRef} style={virtualBlockStyle(start)}>
              <HTMLBlock
                key={index}
                index={index}
                blockGroup={blockGroups[index]}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default DocumentAnnotation;
