import { useEffect } from "react";
import { Button } from "antd";
import CollectionsTable from "./CollectionsTable";
import { showSlideInView } from "../../../store/actions";
import CollectionForm from "./CollectionForm";
import { useDispatch } from "react-redux";
import { resetCollectionsState } from "../../../store/actions/collections";
import PlusIcon from "../../../assets/svgs/entities/plus.svg";

const Collections = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    return () => dispatch(resetCollectionsState());
  }, []);

  return (
    <div>
      <div className="left-sidebar-options-panel">
        <div className="left-sidebar-options-panel-mainHead">
          <div className="left-sidebar-options-panel-Header">
            <h2 className="left-sidebar-options-panel-Heading">Buckets</h2>
            <div className="rulesButton">
              <Button
                onClick={() => {
                  dispatch(
                    showSlideInView(
                      CollectionForm,
                      { collectionId: null },
                      { title: "New Collection", width: 500 }
                    )
                  );
                }}
                className="rulesButton-addRule"
              >
                Add bucket
                <img
                  src={PlusIcon}
                  alt="Plus-icon"
                  height={20}
                  width={20}
                  className="rulesButton-icon"
                />
              </Button>
            </div>
          </div>
          <CollectionsTable data={[]} columns={[]} activeTab={[]} />
        </div>
      </div>
    </div>
  );
};

export default Collections;
