export const SELECTION_MARKER = "-1";
export const TEXT_SELECTION_MARKER = "text-selection-marker";
export const COMMON_PARENT = "common-parent";
export const TEXT_PARENT = "text-node-parent";
export const WRAPPER_PARENT = "wrapper-parent";
export const GROUP_CONTAINER = "group-container";
export const GROUP_CONTAINER_P = `${GROUP_CONTAINER}-p`;
export const GROUP_CONTAINER_LIST = `${GROUP_CONTAINER}-list`;
export const HIGHLIGTHED_NODE = "highlighted-node";
export const HIGHLIGTHED_PLAIN_NODE = "highlighted-plain-node";
export const INTERSET_NODE = "interset-node";
export const TEXT_ENTITY_CLASS = "entity-node";
export const RELATED_NODE = "related-node";
export const SHOW_ALL_RELATIONSHIPS = "SHOW_ALL_RELATIONSHIPS";
export const HIDE_ALL_RELATIONSHIPS = "HIDE_ALL_RELATIONSHIPS";
export const SHOW_SOME_RELATIONSHIPS = "SHOW_SOME_RELATIONSHIPS";
export const SENTENCE_ENTITY_CLASS = "sentence-entity";
export const ANALYSIS_BLOCKS = "analysis-blocks";
export const ANALYSIS_TABLE = "analysis-table";
export const ATTACK_PATTERN = "attack-pattern";
export const SDO_LOCATION = "location";
export const PARAGRAPH_GROUP = "p-group";
export const PARAGRAPH_GROUP_END = "p-group-end";
export const TRY_AGAIN = "try-again";
export const PROCESSING = "processing";
export const SCROLL_THROTTLE_MS = 500;
export const METADATA_KEYS = "Keys";
export const METADATA_PROFILES = "Profiles";
export const CUSTOM_ADHOC = "custom_adhoc";
export const CUSTOM_KEY_SUFFIX = "_:key:";
export const OVERRIDES = "OVERRIDES";
export const MIXED = "MIXED";
export const DEFAULT_PREFIX = "aitl";
export const ACCEPT_ACCROSS_DOCUMENT = "ACCEPT_ACCROSS_DOCUMENT";
export const ACCEPT_ALWAYS = "ACCEPT_ALWAYS";
export const REJECT_ACCROSS_DOCUMENT = "REJECT_ACCROSS_DOCUMENT";
export const REJECT_ALWAYS = "REJECT_ALWAYS";
export const NO_DROP = "NO_DROP";
export const USER_PROFILE = "Profile";
export const USER_APIS = "Apis";

export const uploadedDocumentStatus = {
  for_review: "For Review",
  reviewing: "Reviewing",
  completed: "Completed",
};

export const documentStatus = {
  FOR_REVIEW: "for_review",
  REVIEWING: "reviewing",
  COMPLETED: "completed",
  QUEUE: "processing",
  NONE: "none",
};

export const reportStatusLabels = {
  [documentStatus.FOR_REVIEW]: "For review",
  [documentStatus.REVIEWING]: "Reviewing",
  [documentStatus.COMPLETED]: "Completed",
};

export const documentFilters = {
  SEARCH: "search_string",
  IMPORTED_BY: "creator_user_id",
  COLLECTION: "collection_id",
  ACCESS: "access_level",
};

export const documentColumns = {
  [documentStatus.FOR_REVIEW]: {
    title: "To review",
    color: "#3498DB",
    loaderRepeat: 5,
  },
  [documentStatus.REVIEWING]: {
    title: "Reviewing",
    color: "#F39C12",
    loaderRepeat: 1,
  },
  [documentStatus.COMPLETED]: {
    title: "Completed",
    color: "#28B463",
    loaderRepeat: 7,
  },
  [documentStatus.QUEUE]: {
    title: "Queue",
    color: "#884ED8",
    loaderRepeat: 3,
  },
};

export const entityClass = {
  SENTENCE: "sentence",
  TEXT: "text",
  TABLE: "table",
};

export const blockType = {
  SENTENCE: "sentence",
  TABLE: "table",
};

export const entityScope = {
  INLINE: "inline",
  BLOCK: "block",
  MULTILINE: "multiline",
};

export const entityRole = {
  SOURCE: "source",
  TARGET: "target",
};

export const entityStatus = {
  REJECTED: "rejected",
  ACCEPTED: "accepted",
  COMPLETED: "completed",
  WAITING: "waiting_for_confirmation",
};

export const entityStatusProps = {
  [entityStatus.WAITING]: {
    label: "Unconfirmed Entities",
    action: "waiting",
    loaderRepeat: 5,
  },
  [entityStatus.ACCEPTED]: {
    label: "Accepted Entities",
    action: "accept",
    loaderRepeat: 3,
  },
  [entityStatus.REJECTED]: {
    label: "Rejected Entities",
    action: "reject",
    loaderRepeat: 4,
  },
};

export const dragItems = {
  CARD: "card",
};

export const storageIds = {
  SELECTION_META: "selection_meta",
  RELATIONSHIP_TYPE: "relationship_type",
  CONFIDENCE_FILTER: "confidence_filter",
  ADD_ENTITY: "add_entity",
  CHANGE_ENTITY: "change_entity",
};

export const promiseTypeSuffixes = ["_LOADING", "_SUCCESS", "_FAILED"];
export const promiseTypeDefault = {
  loading: false,
  result: {},
  error: null,
};

export const appRoles = {
  CREATE_ORG_DOC: "create_org_document",
  CREATE_PRIVATE_DOC: "create_private_document",
  MANAGE_ORG_SETTINGS: "manage_org_settings",
  ORGANISATION_METADATA: "organisation_metadata",
  MANAGE_ORG_COLLECTIONS: "manage_org_collections",
  ALL: "all_roles",
};

export const documentColumnCountMapper = {
  for_review: "for_review_count",
  reviewing: "in_review_count",
  completed: "completed_count",
};

export const TLP = {
  WHITE: "WHITE",
  GREEN: "GREEN",
  AMBER: "AMBER",
  RED: "RED",
};

export const documentAccessLevels = {
  PRIVATE: "private",
  ORGANISATION: "organisation",
};

export const analysisModes = {
  ANNOTATION: " Annotation",
  GRAPH: " Graph",
  EXTRACTION_OVERVIEW: "Extraction Overview",
  EXECUTIVE_SUMMARY: "Executive Summary",
};

export const confidenceScoreModes = {
  ALMOST_CERTAIN: "almost-certain",
  VERY_LIKELY: "very-likely",
  PROBABLE: "probable",
};

export const RULE_TYPES = {
  annotate: { label: "Annotate", value: "annotate" },
  do_not_annotate: { label: "Do not annotate", value: "do_not_annotate" },
};

export const staticDateRanges = {
  all_time: "All time",
  last_month: "Last month",
  last_quarter: "Last quarter",
  last_year: "Last year",
  custom: "Custom",
};

export const docSortBy = {
  most_relevant: "Most relevant",
  newest_publish: "Newest - Publish date",
  oldest_publish: "Oldest - Publish date",
  newest_upload: "Newest - Upload date",
  oldest_upload: "Oldest - Upload date",
};
