import { Upload, Button, Form, message } from "antd";
import { debounce } from "lodash";
import useAxios from "../../../hooks/useAxios";
import api from "../../../constants/api";
import { useState } from "react";
import { useAddMultipleDocs } from "../../../hooks/useDocumentsUpdate";
import { documentStatus, documentAccessLevels } from "../../../constants";
import errorMessages from "../../../utils/messages/errorMessages";
import infoMessages from "../../../utils/messages/infoMessages";
import { AccessLevelButton } from "./AccessLevelButton";
import { DocumentEntityTypes } from "./DocumentEntityTypes";
import { knownEntityTypes } from "../../../constants/entityTypes";
import UploadListItem from "./UploadListItem";
import {
  useMessageBoxError,
  useMessageBoxSuccess,
} from "../../../hooks/useMessageBox";
import messageBox from "../../../utils/services/MessageBox";

const FILE_FIELD = "documents";
const FILE_TYPES = [".pdf", ".html", ".htm"];
const MAX_COUNT = 5;
const defaultCheckedList = Object.values(knownEntityTypes);

const { supported_documents } = infoMessages;

const flashMaxFilesMessage = debounce(
  () =>
    message.warn(`Only ${MAX_COUNT} of the selected document will be uploaded`),
  1000
);

/**
 * A form component for dragging and uploading files
 *
 * @function
 * @returns React Virtual DOM
 */
const FileImport = ({ handleCancel }) => {
  const [loading, setLoading] = useState(false);
  const [files, setFiles] = useState([]);
  const [{ result, error }, uploadFile] = useAxios(null, "POST", {
    contentType: api.contentType.FORM_DATA,
  });
  const [form] = Form.useForm();
  const [accessLevelType, setAccessLevelType] = useState(
    documentAccessLevels.ORGANISATION
  );
  const [entityList, setEntityList] = useState([...defaultCheckedList]);
  const addMultipleDocs = useAddMultipleDocs();
  useMessageBoxSuccess(result);
  useMessageBoxError(error);

  const handleSubmit = async () => {
    if (!files.length) return messageBox.showError(errorMessages.select_files);
    setLoading(true);
    await Promise.allSettled(
      files.map(async (file) => {
        const formData = new FormData();
        formData.append("file", file);
        formData.append("access_level", accessLevelType);
        formData.append("entity_list", entityList);
        formData.append("all_entity_list", defaultCheckedList);

        const res = await uploadFile(api.UPDLOAD_FILE, formData);
        if (res?.result) {
          addMultipleDocs(
            res.result,
            { title: file.name },
            documentStatus.QUEUE
          );
        }
      })
    );

    setLoading(false);
    form.resetFields();
    setFiles([]);
  };

  const getDocumentAccessType = (docType) => {
    setAccessLevelType(docType);
  };

  const getCheckedEntityList = (list) => {
    const actualEntityTypes = list.map(
      (entityType) => `${knownEntityTypes[entityType]}`
    );
    setEntityList(actualEntityTypes);
  };

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }

    return e && e.fileList;
  };

  const beforeUpload = (formFile) => {
    setFiles((prevState) => {
      if (prevState.length >= MAX_COUNT) flashMaxFilesMessage();

      return prevState.length >= MAX_COUNT
        ? prevState.slice(0, MAX_COUNT)
        : [...prevState, formFile];
    });
    return false;
  };

  const handleClick = () => {
    form.resetFields();
    handleCancel();
  };
  return (
    <Form form={form} layout="vertical" onFinish={handleSubmit}>
      <Form.Item className="fileimport">
        <Form.Item
          name={FILE_FIELD}
          valuePropName="fileList"
          getValueFromEvent={normFile}
          rules={[{ required: true, message: errorMessages.select_files }]}
        >
          <Upload.Dragger
            name="files"
            beforeUpload={beforeUpload}
            listType="picture"
            maxCount={MAX_COUNT}
            accept={FILE_TYPES.join(" ,")}
            multiple
            style={{ marginBottom: "16px" }}
            itemRender={(a, file, fileList, actions) => {
              return (
                <UploadListItem
                  files={files}
                  setFiles={setFiles}
                  file={file}
                  fileList={fileList}
                  actions={actions}
                />
              );
            }}
          >
            <div className="upload-view">
              <div className="upload-view-wrapper">
                <div className="upload-view-file-title">
                  <p className="upload-view-title-text">Select your files</p>
                </div>
                <span className="upload-view-title-text-drag">
                  or drag them into workspace
                </span>
                <p className="upload-view-title-text-data">
                  <em>{supported_documents}</em>
                </p>
                <p
                  className="upload-view-title-text-data"
                  style={{ marginBottom: "17px" }}
                >
                  <em>(Maximum of {MAX_COUNT} files in a single upload)</em>
                </p>
              </div>
            </div>
          </Upload.Dragger>
        </Form.Item>
      </Form.Item>
      <AccessLevelButton getDocumentAccessType={getDocumentAccessType} />
      <DocumentEntityTypes checkedEntityList={getCheckedEntityList} />
      <div className="import-button-head">
        <Button block className="import-button-cancel" onClick={handleClick}>
          Cancel
        </Button>
        <Button
          htmlType="submit"
          loading={loading}
          block
          className="import-button-import"
        >
          Import
        </Button>
      </div>
    </Form>
  );
};

export default FileImport;
