import { Modal } from "antd";
import stixConfig from "../../configs/stix";
import { entityRole } from "../../constants";
import {
  getStixFirstEntityValidationConfig,
  getStixValidationMessageConfig,
} from "../messages/popup/relationship";

const { sources, targets, sourceTargetRelationTypes, version } =
  stixConfig["stix2_1"];

const alternateRole = {
  [entityRole.SOURCE]: entityRole.TARGET,
  [entityRole.TARGET]: entityRole.SOURCE,
};

export const validateFirstEntityInRelationship = (firstEntity, role) => {
  const { type: entityType } = firstEntity;

  let warningMessage = "";

  if (!sources.has(entityType) && !targets.has(entityType)) {
    warningMessage = (
      <p>
        Relationship with a{" "}
        <em>
          <strong>{entityType}</strong>
        </em>{" "}
        entity is not supported in STIX <em>{version}</em>
      </p>
    );
  } else if (role === entityRole.SOURCE && !sources.has(entityType)) {
    warningMessage = (
      <p>
        {entityType} cannot be a {entityRole.SOURCE} entity in STIX {version}{" "}
        relationship
      </p>
    );
  } else if (role === entityRole.TARGET && !targets.has(entityType)) {
    warningMessage = (
      <p>
        {entityType} cannot be a {entityRole.TARGET} entity in STIX {version}{" "}
        relationship
      </p>
    );
  }

  if (warningMessage) {
    Modal.error(getStixFirstEntityValidationConfig(warningMessage));
    return false;
  }

  return true;
};

export const validateEntitiesInNewRelationship = (
  source,
  target,
  newRelationReuest,
  dispatch
) => {
  const sourceAsSource = `${source.type}:-:${target.type}`;
  const targetAsSource = `${target.type}:-:${source.type}`;

  let warningMessage = null;
  let swapOptions = null;

  if (
    !sourceTargetRelationTypes[sourceAsSource] &&
    !sourceTargetRelationTypes[targetAsSource]
  ) {
    warningMessage = (
      <p>
        Relationship between{" "}
        <em>
          <strong>{source.type}</strong>
        </em>{" "}
        and{" "}
        <em>
          <strong>{target.type}</strong>
        </em>{" "}
        entities is not supported in STIX {version}
      </p>
    );
  } else if (!sourceTargetRelationTypes[sourceAsSource]) {
    const firstEntity = source.first ? source : target;
    const secondEntity = source.second ? source : target;
    warningMessage = (
      <p>
        <em>
          <strong>{secondEntity.type}</strong>
        </em>{" "}
        entity cannot be the{" "}
        <em>
          <strong>{secondEntity.role}</strong>
        </em>{" "}
        in a relationship with{" "}
        <em>
          <strong>{firstEntity.type}</strong>
        </em>{" "}
        entity in STIX {version}
      </p>
    );

    swapOptions = {
      role: firstEntity.role,
      sendRequest: (shouldSwap) => {
        if (shouldSwap) newRelationReuest(target, source);
      },
    };
  }

  if (warningMessage) {
    Modal.confirm(
      getStixValidationMessageConfig(warningMessage, swapOptions, dispatch)
    );
    return false;
  }

  return true;
};

export const validateByStix = (
  node,
  role,
  newRelation,
  setOptions,
  clearNewRelation
) => {
  const { type: entity } = node;
  const firstRole = role;
  const secondRole = alternateRole[role];
  const thisRole = newRelation ? secondRole : firstRole;
  const showWarning = newRelation ? Modal.confirm : Modal.error;

  let warningMessage = "";

  if (!sources.has(entity) && !targets.has(entity)) {
    warningMessage = (
      <p>
        Relationship with a{" "}
        <em>
          <strong>{entity}</strong>
        </em>{" "}
        entity is not supported in STIX <em>{version}</em>
      </p>
    );
    showWarning(
      getStixValidationMessageConfig(warningMessage, clearNewRelation)
    );
    return false;
  } else if (thisRole === entityRole.SOURCE && !sources.has(entity)) {
    warningMessage = (
      <p>
        <em>
          <strong>{entity}</strong>
        </em>{" "}
        cannot be a{" "}
        <em>
          <strong>{entityRole.SOURCE}</strong>
        </em>{" "}
        entity in STIX {version} relationship
      </p>
    );
    showWarning(
      getStixValidationMessageConfig(warningMessage, clearNewRelation)
    );
    return false;
  } else if (thisRole === entityRole.TARGET && !targets.has(entity)) {
    warningMessage = (
      <p>
        <em>
          <strong>{entity}</strong>
        </em>{" "}
        cannot be a{" "}
        <em>
          <strong>{entityRole.TARGET}</strong>
        </em>{" "}
        entity in STIX {version} relationship
      </p>
    );
    showWarning(
      getStixValidationMessageConfig(warningMessage, clearNewRelation)
    );
    return false;
  } else if (newRelation) {
    const { source, target } = newRelation;
    const firstEntity = !!source ? source.type : target.type;
    const keyAsSource = `${entity}-${firstEntity}`;
    const keyAsTarget = `${firstEntity}-${entity}`;
    const relationType =
      thisRole === entityRole.SOURCE ? keyAsSource : keyAsTarget;
    const swap = {
      role,
      entity,
      onChange: (checked) => {
        setOptions((options) => ({
          ...options,
          shouldSwap: checked,
          firstRole: checked ? role : undefined,
          secondRole: checked ? thisRole : undefined,
          secondNode: undefined,
        }));
      },
      setSecondNode: () =>
        setOptions((options) => ({ ...options, secondNode: node })),
    };

    if (
      !sourceTargetRelationTypes[keyAsSource] &&
      !sourceTargetRelationTypes[keyAsTarget]
    ) {
      warningMessage = (
        <p>
          Relationship between{" "}
          <em>
            <strong>{entity}</strong>
          </em>{" "}
          and{" "}
          <em>
            <strong>{firstEntity}</strong>
          </em>{" "}
          entities is not supported in STIX {version}
        </p>
      );
      showWarning(
        getStixValidationMessageConfig(warningMessage, clearNewRelation)
      );
      return false;
    }
    if (!sourceTargetRelationTypes[relationType]) {
      warningMessage = (
        <p>
          <em>
            <strong>{entity}</strong>
          </em>{" "}
          entity cannot be the{" "}
          <em>
            <strong>{thisRole}</strong>
          </em>{" "}
          in a relationship with{" "}
          <em>
            <strong>{firstEntity}</strong>
          </em>{" "}
          entity in STIX {version}
        </p>
      );
      showWarning(
        getStixValidationMessageConfig(warningMessage, clearNewRelation, swap)
      );
      return false;
    }
  }

  return true;
};
