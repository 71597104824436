import { Tabs } from "antd";
import URLImport from "./URLImport";
import FileImport from "./FileImport";
import TextImport from "./TextImport";
import { useDocumentsReloadInterval } from "../../../hooks/useReload";
import { documentStatus } from "../../../constants";
import FeaturedIcon from "../../../assets/svgs/entities/FeaturedIcon.svg";

const { COMPLETED, REVIEWING, FOR_REVIEW, QUEUE } = documentStatus;

const { TabPane } = Tabs;

const URL_PANE = "URL";
const TEXT_PANE = "Text";
const FILE_PANE = "File";

const setTabTitle = (title) => <>{title}</>;

const ImportTabs = ({ handleCancel }) => {
  useDocumentsReloadInterval([QUEUE, FOR_REVIEW, REVIEWING, COMPLETED]);

  return (
    <>
      <h2 className="import-modal-heading-icon">
        <img src={FeaturedIcon} alt="upload-icon" height={48} width={48} />
      </h2>
      <h2 className="import-modal-heading">
        <b className="import-modal-heading-title"> Import </b>
      </h2>
      <Tabs
        defaultActiveKey={URL_PANE}
        className="tabsAll"
        tabBarStyle={{ border: "none" }}
      >
        <TabPane
          tab={setTabTitle(URL_PANE)}
          key={URL_PANE}
          className="tabs-title"
        >
          <URLImport handleCancel={handleCancel} />
        </TabPane>
        <TabPane
          tab={setTabTitle(TEXT_PANE)}
          key={TEXT_PANE}
          className="tabs-title"
        >
          <TextImport handleCancel={handleCancel} />
        </TabPane>
        <TabPane
          tab={setTabTitle(FILE_PANE)}
          key={FILE_PANE}
          className="tabs-title"
        >
          <FileImport handleCancel={handleCancel} />
        </TabPane>
      </Tabs>
    </>
  );
};

export default ImportTabs;
