import { useSelector } from "react-redux";
import { Collapse } from "antd";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { useState } from "react";

const { Panel } = Collapse;
const orderOfSummary = [
  "incident_overview",
  "attribution_threat_actor",
  "impact_assessment",
  "incident_response",
  "security_measures_recommendations",
  "future_risks_mitigation",
];

/**
 * ExcecutiveSummary
 *
 * @returns React JSX
 */
const ExcecutiveSummary = ({}) => {
  const [activePanels, setActivePanels] = useState(new Set());
  const { fullSummary } = useSelector(({ analysis }) => analysis);
  const { executive_summary, summary, evidence_statements } = fullSummary || {};

  return (
    <div id="executive-summary" className="section-card">
      <div className="section-card-body full-height">
        {!!executive_summary && (
          <section>
            <h2 className="mb-10 mt-15">Executive Summary</h2>
            <p>{executive_summary}</p>
          </section>
        )}
        {!!summary && (
          <section>
            <Collapse
              defaultActiveKey={orderOfSummary}
              onChange={(p) => setActivePanels(new Set(p))}
              ghost
            >
              {orderOfSummary.map((key) => {
                const summaryText = summary[key];
                if (!summaryText) return null;
                return (
                  <Panel
                    className="ml-0 pl-0"
                    showArrow={false}
                    header={
                      <h2 className="align-center mb-0">
                        {key
                          .split("_")
                          .map((t) => t[0].toUpperCase() + t.slice(1))
                          .join(" ")}
                        <span className="f-gray-400 ml-5 mt-5">
                          {activePanels.has(key) ? (
                            <IoIosArrowUp />
                          ) : (
                            <IoIosArrowDown />
                          )}
                        </span>
                      </h2>
                    }
                    key={key}
                  >
                    <p>{summaryText}</p>
                  </Panel>
                );
              })}
            </Collapse>
          </section>
        )}
        {!!evidence_statements && (
          <section>
            <h2 className="mb-10 mt-15">Evidence Statements:</h2>
            <ul>
              {evidence_statements.map((s) => (
                <li>{s}</li>
              ))}
            </ul>
          </section>
        )}
      </div>
    </div>
  );
};

export default ExcecutiveSummary;
