import { useEffect, useState } from "react";
import { Row, Col, DatePicker, Select } from "antd";
import qs from "qs";
import moment from "moment";
import TotalStatWidget from "./TotalStatWidget";
import "../../../assets/styles/performance-dashboard.scss";
import ReportCard from "./ReportCard";
import OverviewCard from "./OverviewCard";
import {
  formatDate,
  getAnnotationOptions,
  getConfScoreOptions,
  getTimeSavedChartOptions,
} from "./chartOptions";
import { useDispatch, useSelector } from "react-redux";
import {
  getExtractionPerformanceRequest,
  setExtractionPerformance,
  resetDashboardState,
} from "../../../store/actions/dashboard";
import { useReload } from "../../../hooks/useReload";
import { formatEstimatedManualTime } from "./helper";
import ChevronDown from "../../../assets/svgs/entities/ChevronDown.svg";
import dayjs from "dayjs";
import localeData from "dayjs/plugin/localeData";
import advancedFormat from "dayjs/plugin/advancedFormat";
import weekday from "dayjs/plugin/weekday";

dayjs.extend(localeData);
dayjs.extend(advancedFormat);
dayjs.extend(weekday);

const activeRealm = window._env_.AUTH_REALM;

export const disabledDate =
  (dateString = "") =>
  (current) => {
    let disabled = false;
    if (current) {
      disabled =
        current < moment(dateString).endOf("day") ||
        current > moment().endOf("day");
    }
    return disabled;
  };

const infoStyle = { width: "170px", color: "#fff" };

const PerformanceDashboard = () => {
  const [queryParams, setQueryParams] = useState({});
  const [selectedUser, setselectedUser] = useState(null);

  const dispatch = useDispatch();

  const { performance, getExtractionPerformance: gef } = useSelector(
    ({ dashboard }) => dashboard
  );

  useReload(gef.result, () =>
    dispatch(setExtractionPerformance(gef.result.data))
  );

  const onCompletedOnly = (checked) => {
    const document_status = checked ? "completed" : "";
    setQueryParams((p) => ({ ...p, document_status }));
  };

  const {
    summary,
    users,
    realms,
    automated_extraction_durations,
    manual_annotation_durations,
    admin_ream,
    begin_date,
    estimated_manual_annotation_time_by_entity_type: estManualTime,
  } = performance;
  const [prev_start_date, prev_end_date] = performance.prev_date_cycle;
  const compareText = `${formatDate(prev_start_date)} - ${formatDate(
    prev_end_date
  )}`;

  const [selectedDates, setSelectedDates] = useState([
    dayjs().startOf("month"),
    dayjs(),
  ]);

  const onDateRangeChange = (dates, dateStrings) => {
    if (dates && dateStrings.length === 2) {
      const [start_date, end_date] = dateStrings.map((date) =>
        moment(date, "D MMM YY").format("YYYY-MM-DD")
      );
      setSelectedDates(dates);
      setQueryParams((p) => ({
        ...p,
        start_date,
        end_date,
      }));
    }
  };

  useEffect(() => {
    if (queryParams.start_date && queryParams.end_date) {
      setSelectedDates([
        dayjs(queryParams.start_date, "YYYY-MM-DD"),
        dayjs(queryParams.end_date, "YYYY-MM-DD"),
      ]);
    }
  }, [queryParams]);

  useEffect(() => {
    dispatch(getExtractionPerformanceRequest(qs.stringify(queryParams)));
    return () => dispatch(resetDashboardState());
  }, [queryParams]);
  const formatCompareText = (count) => {
    if (count) return `${count} (${compareText})`;
  };
  useEffect(() => {
    setselectedUser(users.find((user) => user.id === queryParams?.user_id));
  }, [queryParams?.user_id]);
  // const estManualList = formatEstimatedManualTime(estManualTime);
  const estInfo = (
    <div style={infoStyle}>
      Estimated manual annotation time by entity type
      <br />
      <br />
      {formatEstimatedManualTime(estManualTime).map((e, i) => (
        <span key={i}>
          {e} <br />
        </span>
      ))}
    </div>
  );

  const { Option } = Select;
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModalVisibleStatus, setIsModalVisibleStatus] = useState(false);

  const { RangePicker } = DatePicker;

  return (
    <div
      id="extraction-performance-dashboard"
      className="left-sidebar-options-panel"
    >
      <div
        className="left-sidebar-options-panel-mainHead"
        style={{ padding: "24px" }}
      >
        <h2 className="justify-between left-sidebar-options-panel-Heading mb-20">
          Performance
        </h2>
        <div
          className="left-sidebar-options-panel-dropdownHead justify-between"
          style={{ marginBottom: "24px" }}
        >
          <div>
            {activeRealm !== admin_ream && (
              <Select
                value={
                  <>
                    Users
                    <div className="suffix-icon-all">
                      {queryParams?.user_id
                        ? `${selectedUser?.first_name} ${selectedUser?.last_name}`
                        : "All"}
                    </div>
                  </>
                }
                onChange={(value) =>
                  setQueryParams((p) => ({ ...p, user_id: value }))
                }
                className="filter-selector"
                suffixIcon={
                  <img
                    src={ChevronDown}
                    alt="down-arrow"
                    className={isModalVisible && "rotate-180"}
                  />
                }
                dropdownClassName="filter-selector-options"
                style={{ marginRight: "8px" }}
                onDropdownVisibleChange={setIsModalVisible}
              >
                <Option value="" className={`filter-selector-options-item All`}>
                  <span className="All">All</span>
                </Option>
                {users.map(({ id, first_name, last_name }) => (
                  <Option
                    key={id}
                    value={id}
                    className={`filter-selector-options-item ${first_name}-${last_name}`}
                  >
                    <span>{`${first_name} ${last_name}`}</span>
                  </Option>
                ))}
              </Select>
            )}
            {activeRealm === admin_ream && (
              <Select
                value="Organisations"
                onChange={(value) =>
                  setQueryParams((p) => ({ ...p, org_id: value }))
                }
                className="filter-selector"
                suffixIcon={
                  <img
                    src={ChevronDown}
                    alt="down-arrow"
                    className={isModalVisible && "rotate-180"}
                  />
                }
                dropdownClassName="filter-selector-options"
                style={{ width: "139px" }}
                onDropdownVisibleChange={setIsModalVisible}
              >
                <Option value="" className="filter-selector-options-item All">
                  <span className="All">All</span>
                </Option>
                {realms.map(({ id, display_name }) => (
                  <Option
                    key={id}
                    value={id}
                    className={`filter-selector-options-item ${display_name}`}
                  >
                    <span>{display_name}</span>
                  </Option>
                ))}
              </Select>
            )}
            <Select
              value={
                <>
                  Status
                  <div className="suffix-icon-all">
                    {queryParams?.document_status ? "Completed only" : "All"}
                  </div>
                </>
              }
              onChange={onCompletedOnly}
              className="filter-selector statusDropdown"
              suffixIcon={
                <img
                  src={ChevronDown}
                  alt="down-arrow"
                  className={isModalVisibleStatus ? "rotate-180" : ""}
                />
              }
              dropdownClassName="filter-selector-options"
              onDropdownVisibleChange={setIsModalVisibleStatus}
            >
              <Option value="" className="filter-selector-options-item All">
                <span className="All">All</span>
              </Option>
              <Option
                value="completed"
                className="filter-selector-options-item"
              >
                <span>Completed only</span>
              </Option>
            </Select>
          </div>
          <div>
            <RangePicker
              className="custom-range-picker"
              format="D MMM YY"
              value={
                selectedDates && selectedDates.length === 2
                  ? selectedDates
                  : [null, null]
              }
              onChange={onDateRangeChange}
              disabledDate={disabledDate(begin_date)}
              separator="-"
              renderExtraFooter={null}
              suffixIcon={
                <img
                  src={ChevronDown}
                  alt="down-arrow"
                  className="custom-range-picker-icon"
                />
              }
              inputReadOnly
              allowClear={false}
            />
          </div>
        </div>
        <div className="left-sidebar-options-panel-mainBody">
          <div className="section-card left-sidebar-options-panel-innerBody">
            <div
              className="section-card-body left-sidebar-options-panel-body"
              style={{ backgroundColor: "#ffffff" }}
            >
              <section className="total-stats-section left-sidebar-options-panel-section">
                <TotalStatWidget
                  title="TOTAL REPORT IMPORTED"
                  count={summary.total_reports}
                  compare={summary.total_reports_compare}
                />
                <TotalStatWidget
                  title="TOTAL ENTITIES EXTRACTED"
                  count={summary.total_entities}
                  compare={summary.total_entities_compare}
                />
                <TotalStatWidget
                  title="TOTAL RELATIONSHIP IDENTIFIED"
                  count={summary.total_relationships}
                  compare={summary.total_relationships_compare}
                />
              </section>

              <section className="report-section">
                <Row gutter={24}>
                  <Col span={12} className="report-confidence">
                    <ReportCard
                      title="CONFIDENCE REPORT"
                      reports={performance.confidence_reports}
                    />
                  </Col>
                  <Col span={12} className="report-annotation">
                    <ReportCard
                      title="ANNOTATION REPORT"
                      reports={performance.annotation_reports}
                    />
                  </Col>
                </Row>
              </section>
              <section className="overview-section">
                <Row gutter={24}>
                  <Col span={12} className="report-confidence">
                    <OverviewCard
                      title="CONFIDENCE OVERVIEW"
                      data={performance.confidence_overview}
                      dateRanges={performance.date_ranges}
                      optionHandler={getConfScoreOptions}
                    />
                  </Col>
                  <Col span={12} className="report-annotation">
                    <OverviewCard
                      title="ANNOTATION OVERVIEW"
                      data={performance.annotation_overview}
                      dateRanges={performance.date_ranges}
                      optionHandler={getAnnotationOptions}
                    />
                  </Col>
                </Row>
              </section>
              {/* <section className="time-saved-section">
                  <Row gutter={24}>
                    <Col span={12} className="report-confidence">
                      <OverviewCard
                        title="TIME SAVED"
                        data={[
                          manual_annotation_durations,
                          automated_extraction_durations,
                        ]}
                        dateRanges={performance.date_ranges}
                        extraInfo={
                          <DisplayTip
                            overlayClassName={undefined}
                            color={undefined}
                            placement="rightTop"
                            title={estInfo}
                          >
                            <span style={{ borderBottom: "1px dashed #afafaf" }}>
                              How is this calculated?
                            </span>
                          </DisplayTip>
                        }
                        optionHandler={getTimeSavedChartOptions}
                      />
                    </Col>
                  </Row>
                </section> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PerformanceDashboard;
