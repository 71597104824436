import { Layout, Button } from "antd";
import Icon from "../../../assets/svgs/entities/Icon.svg";
import HeaderOptions from "./HeaderOptions";
import ContinuousLoader from "../loaders/ContinuousLoader";
import ImportDocumentModal from "./ImportDocumentModal";
import { useState } from "react";
import QueueDocumentModel from "./QueueDocumentModel";

const Header = ({ loader }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleModalClose = () => {
    setIsModalVisible(false);
  };

  return (
    <Layout.Header className="app-header">
      <div className="import-header">
        <Button className="import-button" onClick={showModal}>
          <p className="import-text">Import</p>
          <img
            src={Icon}
            alt="Document-icon"
            className="import-document-icon"
          />
        </Button>

        <ImportDocumentModal
          isVisible={isModalVisible}
          handleOk={handleModalClose}
          handleCancel={handleModalClose}
        />

        <QueueDocumentModel />
      </div>
      <HeaderOptions />
      <ContinuousLoader
        className="header-loader"
        loading={loader.loading}
        height="5px"
      />
    </Layout.Header>
  );
};

export default Header;
