import { persistReducer } from "redux-persist";
import idbStorage from "./idbStorage";
import graphReducer from "../reducers/graphReducer";

const graphPersistConfig = {
  key: "graph",
  storage: idbStorage,
};

const persistedGraphReducer = persistReducer(graphPersistConfig, graphReducer);

export default persistedGraphReducer;
