import { Button, Tag, Switch } from "antd";
import MoreActions from "./MoreActions";
import { entitySvgs as svgs } from "../../../data/entitiesSvg";
import { entityProperties } from "../../../data/entities";
import { RULE_TYPES } from "../../../constants";
import { useDispatch } from "react-redux";
import {
  disableRuleRequest,
  enableRuleRequest,
} from "../../../store/actions/manualRules";

const RuleStatusColumn = ({ disabled, record }) => {
  const dispatch = useDispatch();
  const status = disabled ? "Disabled" : "Enabled";

  const handleSwitchChange = (checked) => {
    if (checked) {
      dispatch(enableRuleRequest(record.id));
    } else {
      dispatch(disableRuleRequest(record.id));
    }
  };

  return (
    <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
      <Switch
        checked={!disabled}
        onChange={handleSwitchChange}
        style={{ backgroundColor: !disabled ? "#12B76A" : "#EAECF0" }}
      />
      <span className={`tag-style-common ${disabled ? "disabled" : ""}`}>
        {status}
      </span>
      <Button type="text" size="small" style={{ marginLeft: "auto" }}>
        <MoreActions ruleId={record.id} isDisabled={disabled} />
      </Button>
    </div>
  );
};

export const manualRuleColumns = () => [
  {
    title: <span style={{ paddingLeft: "24px" }}>Value</span>,
    dataIndex: "entity_instance",
    ellipsis: true,
    className: "title-column-id",
    render: (value) => <span className="tag-style-value">{value}</span>,
  },
  {
    title: "Entity type",
    dataIndex: "entity_type",
    render: (t) => (
      <Tag className="tag-style-entity-type">
        <img src={svgs[t]} width={16} height={16} alt="entity type" />
        {entityProperties[t]?.label}
      </Tag>
    ),
  },
  {
    title: "Rule",
    dataIndex: "rule",
    render: (rule) => (
      <Tag className={`tag-style-rule ${rule === "annotate" ? "red" : ""}`}>
        {RULE_TYPES[rule].label}
      </Tag>
    ),
  },
  {
    title: "Creator",
    dataIndex: "creator_name",
    responsive: ["md"],
    render: (creator) => <span className="tag-style-common">{creator}</span>,
  },
  {
    title: "Created",
    dataIndex: "created_at",
    render: (d) => <span className="tag-style-common">{d.split(" ")[0]}</span>,
    responsive: ["md"],
  },
  {
    title: <span style={{ position: "relative", left: "-24px" }}>Status</span>,
    dataIndex: "disabled",
    className: "title-column-more-actions-icon",
    render: (disabled, record) => (
      <RuleStatusColumn disabled={disabled} record={record} />
    ),
  },
];
