import { Dropdown, Menu } from "antd";
import {
  EllipsisOutlined,
  EditOutlined,
  CheckOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import ChangeGroupType from "./ChangeGroupType";
import ActionTip from "../../../common/widgets/ActionTip";
import { useDispatch, useSelector } from "react-redux";
import { showSlideInView } from "../../../../store/actions";
import { confirmGroupPopupWarning } from "../../../../utils/messages/popup";
import api from "../../../../constants/api";
import useAxios from "../../../../hooks/useAxios";
import WarningDialog from "../../../common/widgets/WarningDialog";
import { entityStatus } from "../../../../constants";
import { useReloadAnalysis } from "../../../../hooks/useReload";
import { useMessageBoxError } from "../../../../hooks/useMessageBox";

const CHANGE_GROUP_TYPE = "CHANGE_GROUP_TYPE";
const ACCEPT_GROUP_TYPE = "ACCEPT_GROUP_TYPE";
const REJECT_GROUP_TYPE = "REJECT_GROUP_TYPE";

const GroupOptions = ({ entityIds }) => {
  const dispatch = useDispatch();
  const { document } = useSelector(({ analysis }) => analysis);
  const [{ result, error }, updateRequest] = useAxios(null, api.methods.PUT);
  useReloadAnalysis(result);
  useMessageBoxError(error);

  const handleMoreOptions = (props) => {
    const { key, domEvent } = props;
    domEvent.stopPropagation();

    switch (key) {
      case CHANGE_GROUP_TYPE:
        return dispatch(
          showSlideInView(
            ChangeGroupType,
            { entityIds },
            { title: "Change group type" }
          )
        );

      default:
        break;
    }
  };

  const menu = (
    <Menu onClick={handleMoreOptions}>
      <Menu.Item icon={<EditOutlined />} key={CHANGE_GROUP_TYPE}>
        Change group
      </Menu.Item>

      <Menu.Item icon={<CheckOutlined />} key={ACCEPT_GROUP_TYPE}>
        <WarningDialog
          {...confirmGroupPopupWarning(
            document.id,
            entityIds,
            entityStatus.ACCEPTED,
            updateRequest
          )}
        >
          Accept group
        </WarningDialog>
      </Menu.Item>

      <Menu.Item icon={<CloseOutlined />} key={REJECT_GROUP_TYPE}>
        <WarningDialog
          {...confirmGroupPopupWarning(
            document.id,
            entityIds,
            entityStatus.REJECTED,
            updateRequest
          )}
        >
          Reject group
        </WarningDialog>
      </Menu.Item>
    </Menu>
  );

  return (
    <>
      <Dropdown
        onClick={(e) => e.stopPropagation()}
        overlay={menu}
        trigger={["click"]}
      >
        <ActionTip title="More actions">
          <EllipsisOutlined
            style={{ fontSize: "1rem" }}
            className="graycolor"
            onClick={(e) => e.stopPropagation()}
          />
        </ActionTip>
      </Dropdown>
    </>
  );
};

export default GroupOptions;
