import actions from "../actions/graphActions";
import idbStorage from "../redux-persist/idbStorage";

const initialState = {};

const graphReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case actions.SET_NODE_POSITION: {
      const { graphId, nodeId, position } = action.payload;
      const newState = {
        ...state,
        [graphId]: {
          ...state[graphId],
          [nodeId]: position,
        },
      };

      return newState;
    }

    case actions.RESET_GRAPH_STATE:
      return initialState;

    case actions.DELETE_GRAPH: {
      const { graphId } = action.payload;

      const { [graphId]: _, ...restState } = state;

      idbStorage.removeItem(graphId);

      return restState;
    }

    default:
      return state;
  }
};

export default graphReducer;
