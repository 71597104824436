import { Radio } from "antd";
import { useState } from "react";
import { documentAccessLevels } from "../../../constants";
import "../../../assets/styles/access-level-button.scss";
import DisplayTip from "../../common/widgets/DisplayTip";
import infoMessages from "../../../utils/messages/infoMessages";

const { org_doc_access_tip, private_doc_access_tip } = infoMessages;

export const AccessLevelButton = ({ getDocumentAccessType }) => {
  const [state, setState] = useState(documentAccessLevels.ORGANISATION);

  const onChange = (e) => {
    setState(e.target.value);
    getDocumentAccessType(e.target.value);
  };

  return (
    <div>
      <Radio.Group style={{ height: "30px" }} onChange={onChange} value={state}>
        <Radio className="radio-style" value={documentAccessLevels.PRIVATE}>
          <DisplayTip className="modal-radio-title">Private</DisplayTip>
        </Radio>

        <Radio
          className="radio-style"
          value={documentAccessLevels.ORGANISATION}
        >
          <DisplayTip className="modal-radio-title">Organisation</DisplayTip>
        </Radio>
      </Radio.Group>
      <p
        style={{
          fontStyle: "italic",
          fontSize: "12px",
          height: "30px",
          fontWeight: "400",
          color: "#98A2B3",
        }}
      >
        {state === documentAccessLevels.ORGANISATION
          ? org_doc_access_tip
          : private_doc_access_tip}
      </p>
    </div>
  );
};
