import { Row, Col, Button } from "antd";
import { useDispatch, useSelector } from "react-redux";
import TotalStatWidget from "../performanceDashboard/TotalStatWidget";
import ReportCard from "../performanceDashboard/ReportCard";
import { useEffect } from "react";
import {
  getDocumentPerformanceOverviewRequest,
  resetDashboardState,
  setDocumentPerformanceOverview,
} from "../../../store/actions/dashboard";
import { useReload, useReloadAnalysis } from "../../../hooks/useReload";
import {
  HMSToString,
  handleDocStatusUpdate,
  secondsToHMS,
} from "../../../utils/helpers";
import useAxios from "../../../hooks/useAxios";
import { documentStatus } from "../../../constants";
import api from "../../../constants/api";
import DisplayTip from "../../common/widgets/DisplayTip";
import { useMessageBoxError } from "../../../hooks/useMessageBox";

const WORDS_PER_PAGE = 300;
const { PUT } = api.methods;
const { COMPLETED } = documentStatus;

/**
 * DocumentPerformanceOverview
 *
 * @returns React JSX
 */
const DocumentPerformanceOverview = ({ documentId, isCompleted }) => {
  const dispatch = useDispatch();

  const { document_overview: d, getDocumentOverview: gdo } = useSelector(
    ({ dashboard }) => dashboard
  );

  const [{ result, error }, updateRequest] = useAxios(null, PUT);

  useReloadAnalysis(result);
  useMessageBoxError(error);

  useReload(gdo.result, () =>
    dispatch(setDocumentPerformanceOverview(gdo.result.data))
  );

  useEffect(() => {
    dispatch(getDocumentPerformanceOverviewRequest(documentId));
    return () => dispatch(resetDashboardState());
  }, []);

  const {
    total_relationships = 0,
    total_entities = 0,
    words_count = 0,
    manual_time_spent = 0,
    duration = 0,
  } = d.summary;

  const extractionSeconds = duration?.toFixed(1);
  const manualTime = HMSToString(secondsToHMS(manual_time_spent));

  const pagesInfo = (
    <DisplayTip
      overlayClassName={undefined}
      color={undefined}
      placement="rightTop"
      title={`Estimated ${WORDS_PER_PAGE} words per page`}
    >
      <span>{Math.ceil(words_count / WORDS_PER_PAGE)} pages</span>
    </DisplayTip>
  );

  return (
    <div
      id="report-performance-overview"
      className="section-card"
      style={{ zIndex: 1 }}
    >
      <div className="section-card-body full-height extraction-overview">
        <p className="extraction-overview-title">Extraction overview</p>
        <div className="extraction-overview-text">
          READ identified{" "}
          <strong className="extraction-overview-text-bold">
            {total_entities} entities
          </strong>{" "}
          and{" "}
          <strong className="extraction-overview-text-bold">
            {total_relationships} relationships
          </strong>{" "}
          accross {pagesInfo} of your report! We estimate these annotations
          would take an analyst around{" "}
          <strong className="extraction-overview-text-bold">
            {manualTime}{" "}
          </strong>{" "}
          to manually annotate, but READ did it in{" "}
          <strong className="extraction-overview-text-bold">
            {extractionSeconds} seconds
          </strong>
          .
        </div>
        <section className="total-stats-section">
          <TotalStatWidget
            title="EXTRACTION TIME"
            count={extractionSeconds}
            altText={"seconds"}
          />
          <TotalStatWidget
            title="ENTITIES EXTRACTED"
            count={d.summary?.total_entities}
          />
          <TotalStatWidget
            title="RELATIONSHIP IDENTIFIED"
            count={d.summary?.total_relationships}
          />
        </section>
        <section className="report-section">
          <Row gutter={24} style={{ paddingBottom: "130px" }}>
            <Col span={12} className="report-confidence">
              <ReportCard
                title="CONFIDENCE REPORT"
                reports={d.confidence_reports}
              />
            </Col>
            <Col span={12} className="report-annotation">
              <ReportCard
                title="ANNOTATION REPORT"
                reports={d.annotation_reports}
              >
                {!isCompleted && (
                  <div className="empty-report-info">
                    <p>
                      You need to mark this report as completed before we can
                      show you these metrics
                    </p>
                    <Button
                      type="link"
                      onClick={() =>
                        handleDocStatusUpdate(
                          documentId,
                          COMPLETED,
                          "",
                          updateRequest
                        )
                      }
                      className="empty-report-button"
                    >
                      <span className="empty-report-button-text">
                        Mark as complete
                      </span>
                    </Button>
                  </div>
                )}
              </ReportCard>
            </Col>
          </Row>
        </section>
      </div>
    </div>
  );
};

export default DocumentPerformanceOverview;
