import { Menu } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { entityRole } from "../../../../constants";
import { setNewRelationshipRole } from "../../../../store/actions";
import { startRelationshipProcess } from "../../../../utils/helpers/analysis/relationship";
import infoMessages from "../../../../utils/messages/infoMessages";

const { SOURCE, TARGET } = entityRole;

const AddRelationshipMenu = ({ firstEntity }) => {
  const { newRelation } = useSelector(({ relationship }) => relationship);
  const dispatch = useDispatch();
  const firstEntityRole = newRelation.role;

  const handleRoleChange = (role) => {
    dispatch(setNewRelationshipRole(role));
    startRelationshipProcess(firstEntity, role, dispatch);
  };

  return (
    <Menu className="menuborder">
      <Menu.Item
        className="lh-20 source-target"
        onClick={() => handleRoleChange(SOURCE)}
      >
        <div className="Relation">{infoMessages.source}</div>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item
        className="lh-20 source-target"
        onClick={() => handleRoleChange(TARGET)}
      >
        <div className="Relation">{infoMessages.target}</div>
      </Menu.Item>
    </Menu>
  );
};

export default AddRelationshipMenu;
