import { Button, Dropdown, Menu } from "antd";
import QueuePane from "../../pages/documents/QueuePane";
import { useDocumentContext } from "../../../contexts/DocumentContext";
import { useState, useEffect } from "react";
import { documentColumns, documentStatus } from "../../../constants";
import useAxios from "../../../hooks/useAxios";
import api from "../../../constants/api";
import { useDocumentsUpdate } from "../../../hooks/useDocumentsUpdate";
import Check from "../../../assets/svgs/entities/check.svg";
import loader from "../../../assets/svgs/entities/loader.svg";

const { QUEUE, NONE } = documentStatus;

const QueueDocumentModel = () => {
  const [importing, setImporting] = useState(false);
  const [issue, setIssue] = useState(null);

  const [{ loading, result }, fetchData] = useAxios({});

  useDocumentsUpdate(result, QUEUE);
  const { documentData } = useDocumentContext();
  const documents = documentData[QUEUE];
  const { loaderRepeat } = documentColumns[QUEUE];

  const [query] = useState({ page: 1, size: 50 });

  // const documents = result.data.documents;
  const isEmpty = !documents || !documents.length;
  const { page, size } = query;

  useEffect(() => {
    fetchData(
      `${api.DOCUMENT_API}?status=${QUEUE}&page_number=${page}&results_per_page=${size}`
    );
  }, []);
  useEffect(() => {
    const noneStatusCount = documents.filter(
      (doc) => doc.status === NONE
    ).length;
    setIssue(noneStatusCount !== 0 ? noneStatusCount : null);
  }, [documents]);

  useEffect(() => {
    setImporting(
      documents.some(
        (doc) => doc.status !== documentStatus.NONE && doc.status !== "queued"
      )
    );
  }, [documents]);

  const queueMenu = (
    <Menu className="outerHead-box-queuePane">
      <Menu.Item className="outer-box-queuePane">
        <QueuePane />
      </Menu.Item>
    </Menu>
  );

  return (
    <Dropdown
      overlay={queueMenu}
      trigger={["click"]}
      className="dropdown"
      placement="bottomCenter"
    >
      <Button className="import-button-showdata">
        {importing ? (
          <img src={loader} alt="loader" className="import-icon-tick" />
        ) : (
          <img src={Check} alt="tick-icon" className="import-icon-tick" />
        )}
        <p className="import-button-text">
          {importing ? "Importing" : "Up to date"}
          {issue && (
            <span className="import-span-issue">
              {issue} issue{issue > 1 ? "s" : ""}
            </span>
          )}
        </p>
      </Button>
    </Dropdown>
  );
};

export default QueueDocumentModel;
