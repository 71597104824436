import { batch } from "react-redux";
import { storageIds } from "../../../constants";
import {
  appRoles,
  entityStatusProps,
  PARAGRAPH_GROUP,
  PARAGRAPH_GROUP_END,
} from "../../../constants";
import api from "../../../constants/api";
import { analysisHTMLTagMap } from "../../../data/mappers";
import {
  addProcessingEntities,
  removeActiveSelection,
  shouldNotDrawLines,
} from "../../../store/actions";
import { changeSingleEntityRequest } from "../../../store/actions/apiRequests";
import { changeMultipleEntitiesRequest } from "../../../store/actions/apiRequests";
import { storage } from "../../../utils/services/StaticStorage";

// export const getRelationKey = (node) => `${node.blockId}.${node.id}`;

export const pageBodyMarginTop = () => {
  const headerNode = document.getElementById("analysis-header");
  const bodyNode = document.getElementById("analysis-body");
  if (headerNode && bodyNode) {
    bodyNode.style.marginTop = `${headerNode.offsetHeight - 30}px`;
  }
};

export const getHTMLBlockTag = (block, prevBlockTag) => {
  if (typeof block.table_idx === "number") return "table";

  // if (!block.tag && !block.close_tag && prevBlockTag) return prevBlockTag;

  const { tag, close_tag } = block;

  if (!tag && !close_tag) return prevBlockTag;

  if (tag && close_tag) return analysisHTMLTagMap[tag];

  if (tag)
    return tag === analysisHTMLTagMap.p
      ? PARAGRAPH_GROUP
      : analysisHTMLTagMap[tag];

  if (close_tag)
    return close_tag === analysisHTMLTagMap.p
      ? PARAGRAPH_GROUP_END
      : analysisHTMLTagMap[close_tag];
};

export const getTableCellRef = (table_idx, column_idx, row_idx) =>
  `${table_idx}.${column_idx}.${row_idx}`;

export const getTableColumnRowFromRef = (tableCellRef) => {
  const ref = tableCellRef.split(".");

  return {
    table_idx: +ref[0],
    column_idx: +ref[1],
    row_idx: +ref[2],
  };
};

export const getRefFromTableBlockId = (tableBlockId) => {
  const data = tableBlockId.split("-");

  return {
    blockIndex: +data[2],
    ...getTableColumnRowFromRef(data[1]),
  };
};

export const saveMultipleEntitiesType = (
  documentId,
  ids,
  text,
  payload,
  dispatch
) => {
  const processingEntities = {};
  ids.forEach((id) => (processingEntities[id] = true));

  let changeRequest = changeMultipleEntitiesRequest;
  if (ids.length === 1 && !!text) {
    changeRequest = changeSingleEntityRequest;
    storage.save(storageIds.CHANGE_ENTITY, { text, ...payload });
  }

  batch(() => {
    dispatch(changeRequest(documentId, { ids: ids, ...payload }));
    dispatch(addProcessingEntities(processingEntities));
    dispatch(removeActiveSelection());
  });
};

export const confirmMultipleEntitiesStatus = (
  documentId,
  entity_ids,
  status,
  sendRequest
) => {
  const payload = { entity_ids, type: entityStatusProps[status].action };
  sendRequest(
    api.BULK_ENTITIES_STATUS.replace(":documentId", documentId),
    payload
  );
};

export const permitRouteAccess = (routePermissions = [], userRoles) => {
  const allowed = new Set(routePermissions);

  // Allow access if routePermissions is empty or contains 'ALL' roles flag
  if (!allowed.size || allowed.has(appRoles.ALL)) return true;

  // Deny access if user has no role and routePermissions is set
  if (!userRoles || !userRoles.length) return false;

  // Allow access only if an allowed role is found in userRoles
  for (let role of userRoles) if (allowed.has(role)) return true;

  // Deny access as an allowed role is not found in user role.
  return false;
};

export const getRangeExtractor = (indexRef) => (range) => {
  let { start, end } = range;

  if (indexRef.current) {
    end = indexRef.current + (end - start);
    start = indexRef.current;
  }

  start = Math.max(start - range.overscan, 0);
  end = Math.min(end + range.overscan, range.size - 1);
  const indicesToRender = [];

  for (let i = start; i <= end; i++) {
    indicesToRender.push(i);
  }

  return indicesToRender;
};

const MAX_RETRY = 4;
export const handleScrollToEntity = (
  id,
  groupIdx,
  scrollToIndex,
  groupIndexRef,
  dispatch,
  retry = 1
) => {
  const element = document.querySelector(`#entity-${id}`);
  if (element) {
    dispatch(shouldNotDrawLines(false));
    groupIndexRef.current = null;
    element.scrollIntoView({ block: "center" });
    element.classList.add("focus-text");
    setTimeout(() => element.classList.remove("focus-text"), 3000);
  } else if (retry <= MAX_RETRY) {
    groupIndexRef.current = groupIdx;
    scrollToIndex(groupIdx);
    setTimeout(() => {
      handleScrollToEntity(
        id,
        groupIdx,
        scrollToIndex,
        groupIndexRef,
        dispatch,
        retry + 1
      );
    }, retry * 500);
  }
};
