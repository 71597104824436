import { handleOpenAnalysis } from "./helpers";

/**
 * Results - The search results view
 *
 * @function
 *
 * @returns React JSX
 */
export const Results = ({ documents, entityName }) => {
  return (
    <div id="search-results" className="search-results">
      {(documents || []).map((d, i) => {
        return (
          <div
            key={i}
            className="search-results-doc-item"
            onClick={handleOpenAnalysis(d)}
          >
            <div className="search-results-contentHead flex-1">
              <p className="search-results-contentText">
                <span className="search-results-uploaded">UPLOADED</span>
                <span className="search-results-date">
                  {new Date(d.created_at).toLocaleDateString("en-GB")}
                </span>
              </p>
              <h2 className="search-results-title">{d.title}</h2>
              <p className="m-0 search-results-value">
                <span className="search-results-valueText">
                  This report contains{" "}
                </span>
                <strong>{d?.entity_count}</strong>
                <span className="search-results-valueText">
                  {" "}
                  {d?.entity_count > 1 ? "instances" : "instance"} of{" "}
                </span>
                <strong>{entityName}</strong>
              </p>
            </div>
          </div>
        );
      })}
    </div>
  );
};
