import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Checkbox, Collapse, Divider } from "antd";
import { PlusOutlined, MinusOutlined } from "@ant-design/icons";
import EntityList from "../analysis/EntityList";
import ActionTip from "../../common/widgets/ActionTip";
import WarningDialog from "../../common/widgets/WarningDialog";
import {
  acceptNodeGroupConfirmation,
  rejectNodeGroupConfirmation,
  removeMultipleRelationshipsWarning,
} from "../../../utils/messages/popup";
import infoMessages from "../../../utils/messages/infoMessages";
import GroupedRelationshipList from "./GroupedRelationshipList";
import { getRelationshipIdsFromEntityIds } from "../../../utils/helpers/analysis/relationship";
import AppModal from "../../common/modals/AppModal";
import FirstEntityForRelationship from "../analysis/FirstEntityForRelationship";
import { getGroupEntityIds } from "../../../utils/helpers/analysis/entities";
import { ATTACK_PATTERN, documentStatus } from "../../../constants";
import { setSelectedRelationshipIds } from "../../../store/actions/stixGraph";
import { useReload } from "../../../hooks/useReload";
import { useMessageBoxError } from "../../../hooks/useMessageBox";

const { Panel } = Collapse;
const { COMPLETED } = documentStatus;

/**
 * Node group details and options
 *
 * @returns React JSX
 */
const NodeGroup = ({ text, type }) => {
  const [addRelationshipModal, setAddRelationshipModal] = useState(false);

  const { analysis, relationship, stixGraph, analysisApi } = useSelector(
    (state) => state
  );
  const { entityGroups, entityProps, document } = analysis;
  const { entityRelations } = relationship;
  const { selectedRelationshipIds } = stixGraph;
  const { deleteRelationship: dr } = analysisApi;

  const dispatch = useDispatch();

  useReload(dr.result, () => dispatch(setSelectedRelationshipIds()));
  useMessageBoxError(dr.error);

  const entityIds = getGroupEntityIds(entityGroups, type, text);
  const rIds = getRelationshipIdsFromEntityIds(entityIds, entityRelations);
  const color = type === ATTACK_PATTERN ? "#377cb7" : entityProps[type]?.color;

  const isCompleted = document.status === COMPLETED;
  const tip = isCompleted ? "Marked as complete. Cannot be changed." : "";
  const allChecked = selectedRelationshipIds.length === rIds.length;

  const onAllChecked = (e) => {
    if (e.target.checked) {
      dispatch(setSelectedRelationshipIds(rIds));
    } else {
      dispatch(setSelectedRelationshipIds());
    }
  };

  return (
    <div>
      <div className="section-card mb-30">
        <div className="section-card-header px-0">
          <div className="justify-between text-nowrap">
            <span className="text-ellipsis">
              <strong style={{ color }}>
                {(entityProps[type]?.label || type).toUpperCase()}
              </strong>{" "}
              <span>{text}</span>
            </span>
            <span className="ml-10">
              <WarningDialog
                okText="Yes"
                cancelText="No"
                disabled={isCompleted}
                {...acceptNodeGroupConfirmation(
                  document.id,
                  entityIds,
                  dispatch
                )}
              >
                <ActionTip title={tip || infoMessages.accept_group}>
                  <Button
                    size="small"
                    className="btn-accept outline"
                    disabled={isCompleted}
                  >
                    Accept
                  </Button>
                </ActionTip>
              </WarningDialog>
              <WarningDialog
                okText="Yes"
                cancelText="No"
                disabled={isCompleted}
                {...rejectNodeGroupConfirmation(
                  document.id,
                  entityIds,
                  dispatch
                )}
              >
                <ActionTip title={tip || infoMessages.reject_group}>
                  <Button
                    size="small"
                    className="btn-reject outline ml-5"
                    disabled={isCompleted}
                  >
                    Reject
                  </Button>
                </ActionTip>
              </WarningDialog>
            </span>
          </div>
        </div>
        <Collapse defaultActiveKey={["1"]} expandIconPosition="right">
          <Panel
            header={`${infoMessages.instances.toUpperCase()} (${
              entityIds.length
            })`}
            key="1"
          >
            <EntityList entityIds={entityIds} canSelect={false} />
          </Panel>
          <Panel
            header={`${infoMessages.relationships.toUpperCase()} (${
              rIds.length
            })`}
            key="2"
          >
            <>
              <div className="justify-between">
                <span>
                  {!!rIds.length && (
                    <Checkbox
                      className="mr-5"
                      checked={allChecked}
                      onChange={onAllChecked}
                    />
                  )}
                  {!!selectedRelationshipIds.length && (
                    <span>{selectedRelationshipIds.length} selected </span>
                  )}
                </span>
                <div className="justify-end">
                  <span>
                    <ActionTip
                      title={tip || infoMessages.remove_selected_relationships}
                      placement="bottom"
                    >
                      <Button
                        type="text"
                        size="small"
                        className="pr-0"
                        disabled={
                          isCompleted || !selectedRelationshipIds.length
                        }
                      >
                        <WarningDialog
                          {...removeMultipleRelationshipsWarning(
                            document.id,
                            selectedRelationshipIds,
                            dispatch
                          )}
                        >
                          <MinusOutlined />
                        </WarningDialog>
                      </Button>
                    </ActionTip>
                    <ActionTip
                      title={tip || infoMessages.add_relationship}
                      placement="bottom"
                    >
                      <Button
                        type="text"
                        size="small"
                        onClick={() => setAddRelationshipModal(true)}
                        disabled={isCompleted || !entityIds.length}
                      >
                        <PlusOutlined />
                      </Button>
                    </ActionTip>
                  </span>
                </div>
              </div>
              <Divider />
              <GroupedRelationshipList
                relationshipIds={rIds}
                selectedRelationshipIds={selectedRelationshipIds}
                onSelect={(s) => dispatch(setSelectedRelationshipIds(s))}
              />
            </>
          </Panel>
        </Collapse>
        <AppModal
          title={infoMessages.new_relationship}
          visible={addRelationshipModal}
          onOk={() => setAddRelationshipModal(false)}
          onCancel={() => setAddRelationshipModal(false)}
          footer={null}
        >
          <FirstEntityForRelationship
            entityIds={entityIds}
            handleCancel={() => setAddRelationshipModal(false)}
          />
        </AppModal>
      </div>
    </div>
  );
};

export default NodeGroup;
