import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Button, PageHeader } from "antd";
import routes from "../../../../constants/routes";
import { documentStatus } from "../../../../constants";
import HeaderTitle from "./HeaderTitle";
import CompleteTagWidget from "./CompleteTagWidget";
import TLPWidget from "./TLPWidget";
import DownloadStixWidget from "./DownloadStixWidget";
import ViewSource from "../../../../assets/svgs/entities/ViewSource.svg";
import Backicon from "../../../../assets/svgs/entities/Backicon.svg";
import StatusWidget from "./StatusWidget";
const { COMPLETED } = documentStatus;

/**
 * Page header view for analysis page
 *
 * @function
 * @returns React JSX
 */
const AnalysisPageHeader = ({ currentLoading, initialLoading }) => {
  const history = useHistory();
  const { analysis } = useSelector((state) => state);
  const { document, entityProps } = analysis;

  const isCompleted = document.status === COMPLETED;

  let sourceUrl = document.source;
  if (sourceUrl?.endsWith(".pdf") || sourceUrl === "text upload") {
    sourceUrl = routes.DOCUMENT_SOURCE.replace(":documentId", document.id);
  }
  return (
    <div id="analysis-header" className="analysis-header">
      <PageHeader
        className="site-page-header"
        onBack={() => history.push(routes.DOCUMENTS)}
        title={<HeaderTitle initialLoading={initialLoading} />}
        tags={
          !!document.id
            ? [
                <StatusWidget
                  docId={document.id}
                  reportStatus={document.status}
                />,
              ]
            : []
        }
        backIcon={<img src={Backicon} alt="back-icon" height={16} width={16} />}
        extra={[
          <TLPWidget key="tlp-widget" docId={document.id} />,
          <a
            key="document-source"
            href={sourceUrl}
            target="_blank"
            rel="noreferrer"
            onClick={(e) => {
              if (!document.id) e.preventDefault();
            }}
          >
            <Button
              className="btn-viewsource"
              icon={
                <img
                  src={ViewSource}
                  alt="view-source-icon"
                  className="btn-viewsource-icon"
                />
              }
              size="small"
              disabled={!document.id}
            >
              View Source
            </Button>
          </a>,
          <DownloadStixWidget
            key="download-stix-widget"
            isCompleted={isCompleted}
            docId={document.id}
            hasStixBundle={analysis.hasStixBundle}
          />,
        ]}
      />
    </div>
  );
};

export default AnalysisPageHeader;
