import React from "react";
import { Form, Input } from "antd";
import _ from "lodash";
import { useDocumentContext } from "../../../contexts/DocumentContext";
import { documentFilters, documentStatus } from "../../../constants";
import searchIcon from "../../../assets/svgs/entities/searchIcon.svg";

export const SearchInput = () => {
  const { setDocumentData } = useDocumentContext();

  const handleSearchText = (e) => {
    const sText = e.target.value;
    setDocumentData((documentData) => ({
      ...documentData,
      globalAccess: ["All", "Private", "Organisation"],
      [documentFilters.SEARCH]: sText?.trim(),
      access: {
        [documentStatus.FOR_REVIEW]: {
          orgAccessLevel: true,
          privateAccessLevel: true,
        },
        [documentStatus.REVIEWING]: {
          orgAccessLevel: true,
          privateAccessLevel: true,
        },
        [documentStatus.COMPLETED]: {
          orgAccessLevel: true,
          privateAccessLevel: true,
        },
      },
    }));
  };
  return (
    <Form layout="vertical">
      <Form.Item name="search-input">
        <Input
          className="search-and-filters-input"
          placeholder="Filter by document name"
          prefix={
            <img
              src={searchIcon}
              alt="search-icon"
              className="search-and-filters-searchoutlined"
            />
          }
          size="small"
          onKeyUp={_.debounce(handleSearchText, 500)}
        />
      </Form.Item>
    </Form>
  );
};
