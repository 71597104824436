import { useState } from "react";
import { Button, Menu, Dropdown } from "antd";
import { DownloadOutlined, DownOutlined } from "@ant-design/icons";
import { handleDownloadOptions } from "./handlers";
import { useAuthContext } from "../../../../contexts/AuthContext";
import { ReactComponent as Dawnload } from "../../../../assets/svgs/entities/dawnload.svg";

const STIX_DOWNLOAD = "STIX_DOWNLOAD ";
const CSV_DOWNLOAD = "CSV_DOWNLOAD ";
const BASE_URL = window._env_.UNIFIED_SERVER_URL;

const DownloadStixWidget = ({ isCompleted, hasStixBundle, docId }) => {
  const [downloading, setDownloading] = useState(false);
  const { auth } = useAuthContext();

  const stixUrl = `${BASE_URL}/documents/${docId}/stix`;
  const csvUrl = `${BASE_URL}/documents/${docId}/stix_entities_csv`;

  const menu = (
    <Menu>
      <Menu.Item
        key={STIX_DOWNLOAD}
        onClick={handleDownloadOptions(
          stixUrl,
          `stix_bundle_${docId}.json`,
          auth.token,
          setDownloading
        )}
        disabled={!(hasStixBundle || isCompleted)}
      >
        Download as STIX
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item
        key={CSV_DOWNLOAD}
        onClick={handleDownloadOptions(
          csvUrl,
          `stix_entities_${docId}.csv`,
          auth.token,
          setDownloading
        )}
      >
        Download as CSV
      </Menu.Item>
    </Menu>
  );

  return (
    <span>
      <Dropdown overlay={menu} trigger={["click"]}>
        <Button
          size="small"
          shape="round"
          loading={downloading}
          className="btn-viewsource"
        >
          <Dawnload /> Download
        </Button>
      </Dropdown>
    </span>
  );
};

export default DownloadStixWidget;
