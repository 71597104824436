import { useDispatch } from "react-redux";
import { Button } from "antd";
import PlusIcon from "../../../assets/svgs/entities/plus.svg";
import RulesTable from "./RulesTable";
import { showRuleForm } from "./helpers";

const RulesManager = () => {
  const dispatch = useDispatch();

  return (
    <div>
      <div className="left-sidebar-options-panel">
        <div className="left-sidebar-options-panel-mainHead">
          <div style={{ display: "inline-flex", margin: "0px 0px 16px 24px" }}>
            <h2
              className="left-sidebar-options-panel-Heading"
              style={{ margin: "4px 12px 4px 0px" }}
            >
              Rules
            </h2>
            <div className="rulesButton">
              <Button
                onClick={() => showRuleForm(null, dispatch)}
                className="rulesButton-addRule"
              >
                Add rule
                <img
                  src={PlusIcon}
                  alt="Plus-icon"
                  height={20}
                  width={20}
                  className="rulesButton-icon"
                />
              </Button>
            </div>
          </div>
          <RulesTable data={[]} columns={[]} activeTab={[]} />
        </div>
      </div>
    </div>
  );
};

export default RulesManager;
