import React, { useState, useEffect } from "react";
import { knownEntityTypes } from "../../../constants/entityTypes";

const plainOptions = Object.keys(knownEntityTypes);
const defaultCheckedList = Object.keys(knownEntityTypes);

export const DocumentEntityTypes = ({ checkedEntityList }) => {
  const [checkedList, setCheckedList] = useState(defaultCheckedList);
  const [checkAll, setCheckAll] = useState(true);

  const onChange = (option) => {
    const updatedList = checkedList.includes(option)
      ? checkedList.filter((item) => item !== option)
      : [...checkedList, option];

    setCheckedList(updatedList);
    setCheckAll(updatedList.length === plainOptions.length);
    checkedEntityList(updatedList);
  };

  useEffect(() => {
    checkedEntityList(checkedList);
  }, []);

  const onCheckAllChange = (e) => {
    setCheckedList(e.target.checked ? plainOptions : []);
    setCheckAll(e.target.checked);
    checkedEntityList(e.target.checked ? defaultCheckedList : []);
  };

  return (
    <>
      <div className="document-entity-types-header">
        <h4 className="header-text">Entity types to extract</h4>

        <div className="document-entity-types-body">
          <label className={`checkbox-pill ${checkAll ? "checked" : ""}`}>
            <input
              type="checkbox"
              checked={checkAll}
              onChange={onCheckAllChange}
              className="hidden-checkbox"
            />
            All
          </label>
          {plainOptions.map((option) => (
            <label
              key={option}
              className={`checkbox-pill ${
                checkedList.includes(option) ? "checked" : ""
              }`}
            >
              <input
                type="checkbox"
                value={option}
                checked={checkedList.includes(option)}
                onChange={() => onChange(option)}
                className="hidden-checkbox"
              />
              {option}
            </label>
          ))}
        </div>
      </div>
    </>
  );
};
