import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Table, Input, Select, Tooltip } from "antd";
import qs from "qs";
import _ from "lodash";
import { useReload } from "../../../hooks/useReload";
import { setRules, getRulesRequest } from "../../../store/actions/manualRules";
import { manualRuleColumns } from "./columns";
import { removeSlideInView, removeModalView } from "../../../store/actions";
import messageBox from "../../../utils/services/MessageBox";
import { useMessageBoxError } from "../../../hooks/useMessageBox";
import { resetRulesState } from "../../../store/actions/manualRules";
import { RULE_TYPES } from "../../../constants";
import { entityProperties } from "../../../data/entities";
import ChevronDown from "../../../assets/svgs/entities/ChevronDown.svg";
import { getFilterProps, handleFilter, handleSearch } from "./helpers";
import { getRulesCreatorsRequest } from "../../../store/actions/manualRules";
import { handleSorting } from "./helpers";
import searchIcon from "../../../assets/svgs/entities/searchIcon.svg";
import { Pagination } from "./Pagination";

const { Option } = Select;

const entityTypesProps = getFilterProps("Entity type", entityProperties);
const ruleTypesProps = getFilterProps("Rule type", RULE_TYPES);
const membersProps = getFilterProps("Creators");

const RulesTable = () => {
  const [queryParams, setQueryParams] = useState({
    page_number: 1,
    results_per_page: 20,
  });
  const [creators, setCreators] = useState([]);
  const [isDropdownVisible, setIsDropdownVisible] = useState({
    entityType: false,
    ruleType: false,
    creators: false,
  });
  const [selectedCreator, setselectedCreator] = useState();
  const [entitytype, setentitytype] = useState();
  const dispatch = useDispatch();

  const {
    data: { rules, pagination },
    getRules: gr,
    updateRule: u,
    createRule: c,
    deleteRule: d,
    disableRule: dr,
    enableRule: er,
    submitRuleFeedback: sf,
    getRulesCreators: grc,
  } = useSelector(({ manualRules }) => manualRules);

  const loadRulesData = () => {
    dispatch(getRulesRequest(qs.stringify(queryParams)));
  };

  const successActions = (result) => {
    messageBox.show(result.message);
    loadRulesData();
    dispatch(removeSlideInView());
    dispatch(removeModalView());
  };

  useReload(gr.result, () => dispatch(setRules(gr.result.data)));
  useReload(grc.result, () =>
    setCreators([{ name: `All`, id: "" }, ...grc.result.data.creators])
  );
  useReload(u.result, () => successActions(u.result));
  useReload(c.result, () => successActions(c.result));
  useReload(c.result, () => dispatch(getRulesCreatorsRequest()));
  useReload(d.result, () => successActions(d.result));
  useReload(dr.result, () => successActions(dr.result));
  useReload(er.result, () => successActions(er.result));
  useReload(sf.result, () => successActions(sf.result));

  useMessageBoxError(u.error);
  useMessageBoxError(c.error);
  useMessageBoxError(d.error);
  useMessageBoxError(dr.error);
  useMessageBoxError(er.error);
  useMessageBoxError(sf.error);

  useEffect(() => {
    setentitytype(
      entityTypesProps?.options.find(
        (user) => user.value === queryParams?.entity_type
      )
    );
  }, [queryParams?.entity_type]);

  useEffect(() => {
    setselectedCreator(
      creators.find((user) => user.id === queryParams?.creator_id)
    );
  }, [queryParams?.creator_id]);

  useEffect(() => {
    loadRulesData();
    dispatch(getRulesCreatorsRequest());
    return () => dispatch(resetRulesState());
  }, [queryParams]);

  const handleDropdownVisibleChange = (type) => (visible) => {
    setIsDropdownVisible((prev) => ({ ...prev, [type]: visible }));
  };

  const handlePaginationChange = (page, pageSize) => {
    const updatedParams = {
      ...queryParams,
      page_number: page,
      results_per_page: pageSize,
    };
    setQueryParams(updatedParams);
  };
  const rowClassName = (record, index) => {
    return index % 2 === 0 ? "table-even-row" : "table-odd-row";
  };
  return (
    <>
      <div
        className="left-sidebar-options-panel-dropdownHead"
        style={{ display: "flex", gap: "8px", marginLeft: "24px" }}
      >
        <div className="performance-panel-dropdown-body mb-2">
          <Input
            className="performance-panel-searchbar"
            placeholder="Search your rules"
            prefix={
              <img
                src={searchIcon}
                alt="search-icon"
                className="performance-panel-searchbar-searchoutlined"
              />
            }
            size="small"
            onChange={_.debounce(handleSearch(setQueryParams), 1000)}
          />
        </div>
        <div className="performance-panel-dropdown-body mb-2">
          <Select
            value={
              <>
                Entity Type
                <Tooltip
                  title={
                    queryParams?.entity_type
                      ? entityTypesProps?.options.find(
                          (user) => user.value === queryParams?.entity_type
                        )?.label
                      : "All"
                  }
                >
                  <div className="suffix-icon-all">
                    {queryParams?.entity_type ? "1" : "All"}
                  </div>
                </Tooltip>
              </>
            }
            className="filter-selector"
            onChange={(value) =>
              handleFilter("entity_type", setQueryParams)(value)
            }
            virtual={false}
            suffixIcon={
              <img
                src={ChevronDown}
                alt="down-arrow"
                className={isDropdownVisible.entityType ? "rotate-180" : ""}
              />
            }
            dropdownClassName="filter-selector-options rules"
            onDropdownVisibleChange={handleDropdownVisibleChange("entityType")}
          >
            {entityTypesProps?.options?.map(({ value, label }, index) => (
              <Option
                key={value || `option-${index}`}
                value={value}
                className={label}
              >
                {label}
              </Option>
            ))}
          </Select>

          <Select
            value={
              <>
                Rule Type
                <div className="suffix-icon-all">
                  {queryParams?.rule
                    ? ruleTypesProps.options.find(
                        (user) => user?.value === queryParams?.rule
                      )?.label
                    : "All"}
                </div>
              </>
            }
            className="filter-selector"
            onChange={(value) => handleFilter("rule", setQueryParams)(value)}
            suffixIcon={
              <img
                src={ChevronDown}
                alt="down-arrow"
                className={isDropdownVisible.ruleType ? "rotate-180" : ""}
              />
            }
            dropdownClassName="filter-selector-options"
            onDropdownVisibleChange={handleDropdownVisibleChange("ruleType")}
          >
            {ruleTypesProps.options.map(({ value, label }, index) => (
              <Option
                key={value || `option-${index}`}
                value={value}
                className={label}
              >
                {label}
              </Option>
            ))}
          </Select>

          <Select
            value={
              <>
                Creators
                <div className="suffix-icon-all">
                  {queryParams?.creator_id ? selectedCreator?.name : "All"}
                </div>
              </>
            }
            virtual={false}
            className="filter-selector"
            onChange={(value) =>
              handleFilter("creator_id", setQueryParams)(value)
            }
            suffixIcon={
              <img
                src={ChevronDown}
                alt="down-arrow"
                className={isDropdownVisible.creators ? "rotate-180" : ""}
              />
            }
            dropdownClassName="filter-selector-options"
            onDropdownVisibleChange={handleDropdownVisibleChange("creators")}
          >
            {creators.map(({ id, name }, index) => (
              <Option
                key={id || `creator-${index}`}
                value={id}
                className={`filter-selector-options-item ${name}`}
              >
                <span className={name}>{name}</span>
              </Option>
            ))}
          </Select>
        </div>
      </div>
      <div className="left-sidebar-options-panel-mainBody mt-30">
        <Table
          rowKey="id"
          columns={manualRuleColumns(setQueryParams)}
          dataSource={rules}
          onChange={handleSorting(setQueryParams)}
          showSorterTooltip={false}
          pagination={false}
          rowClassName={rowClassName}
          className="buckets-table-collection"
        />
        {pagination?.page && (
          <Pagination
            current={pagination.page}
            total={pagination.pages}
            pageSize={pagination.per_page}
            handlepagniation={handlePaginationChange}
          />
        )}
      </div>
    </>
  );
};

export default RulesTable;
