import { openDB } from "idb";

const DB_NAME = "reduxPersist";
const STORE_NAME = "graphState";

const openDatabase = async (version = 1) => {
  try {
    return await openDB(DB_NAME, version, {
      upgrade(db, oldVersion, newVersion) {
        if (!db.objectStoreNames.contains(STORE_NAME)) {
          db.createObjectStore(STORE_NAME);
        }
      },
    });
  } catch (error) {
    console.error("Error opening DB:", error);
    throw error;
  }
};

const idbStorage = {
  async setItem(key, value) {
    try {
      const db = await openDatabase(1);
      const tx = db.transaction(STORE_NAME, "readwrite");

      await tx.store.put(value, key);
      await tx.done;
    } catch (error) {
      console.error("Error setting item:", error);
    }
  },

  async getItem(key) {
    try {
      const db = await openDatabase(1);
      return db.get(STORE_NAME, key);
    } catch (error) {
      console.error("Error getting item:", error);
    }
  },

  async removeItem(key) {
    try {
      const db = await openDatabase(1);
      const tx = db.transaction(STORE_NAME, "readwrite");
      await tx.store.delete(key);
      await tx.done;
    } catch (error) {
      console.error("Error removing item:", error);
    }
  },
};

export default idbStorage;
