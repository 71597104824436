import { Dropdown, Menu } from "antd";
import ActionTip from "../../common/widgets/ActionTip";
import { deleteRuleRequest } from "../../../store/actions/manualRules";
import { showRuleForm, showRuleFeedbackForm } from "./helpers";
import { useDispatch } from "react-redux";
import morehorizontal from "../../../assets/svgs/entities/morehorizontal.svg";

const DELETE_RULE = "DELETE_RULE";
const EDIT_RULE = "EDIT_RULE";
const SUBMIT_FEEDBACK = "SUBMIT_FEEDBACK";

const MoreActions = ({ ruleId }) => {
  const dispatch = useDispatch();
  const menu = (
    <Menu key={ruleId} className="menu-dropdown-head p-0">
      <Menu.Item
        key={EDIT_RULE}
        onClick={() => showRuleForm(ruleId, dispatch)}
        className="menu-item-rules"
      >
        Edit
      </Menu.Item>
      <Menu.Item
        key={SUBMIT_FEEDBACK}
        onClick={() => showRuleFeedbackForm(ruleId, dispatch)}
        className="menu-item-rules"
      >
        Submit feedback
      </Menu.Item>
      <Menu.Item
        key={DELETE_RULE}
        onClick={() => dispatch(deleteRuleRequest(ruleId))}
        className="menu-item-rules delete"
      >
        <span className="menu-item-dropdown-delete">Delete</span>
      </Menu.Item>
    </Menu>
  );

  return (
    <>
      <Dropdown
        key={ruleId}
        onClick={(e) => e.stopPropagation()}
        overlay={menu}
        trigger={["click"]}
        destroyPopupOnHide={true}
      >
        <ActionTip title="More actions" className="more-actions-rules-page">
          <img
            src={morehorizontal}
            alt="more-actions"
            height={20}
            width={20}
            onClick={(e) => e.stopPropagation()}
          />
        </ActionTip>
      </Dropdown>
    </>
  );
};

export default MoreActions;
