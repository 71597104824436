import { useEffect } from "react";
import _ from "lodash";
import { documentStatus } from "../constants";
import { useDocumentContext } from "../contexts/DocumentContext";
import { uniqArr } from "../utils/helpers/documents/handlers";

const { QUEUE } = documentStatus;

/**
 * useDocumentsUpdate - updates documents data
 *
 */
export const useDocumentsUpdate = (result, column, isPrepend) => {
  const { documentData, setDocumentData } = useDocumentContext();
  const { search_string, creator_user_id, collection_id, access_level } =
    documentData;

  const {
    data: {
      documents,
      for_review_count,
      in_review_count,
      completed_count,
    } = {},
  } = result || {};

  useEffect(() => {
    if (!result || result.loading || !result.success || !documents) return;

    if (column === "QUEUE") {
      setDocumentData((state) => ({
        ...state,
        [column]: documents,
      }));
      return;
    }

    setDocumentData((state) => ({
      ...state,
      [column]:
        column === "processing"
          ? documents
          : isPrepend
          ? uniqArr([...documents, ...state[column]])
          : uniqArr([...state[column], ...documents]),
      for_review_count:
        for_review_count || documentData[documentStatus.FOR_REVIEW].length || 0,
      [documentStatus.REVIEWING + "_count"]:
        in_review_count || documentData[documentStatus.REVIEWING].length || 0,
      completed_count:
        completed_count || documentData[documentStatus.COMPLETED].length || 0,
    }));
  }, [result, column, isPrepend, documents]);

  useEffect(() => {
    if (!result || result.loading || !result.success || !documents) return;

    setDocumentData((state) => ({
      ...state,
      [column]:
        access_level || search_string || creator_user_id || collection_id
          ? documents
          : uniqArr([...state[column], ...documents]),
      for_review_count:
        for_review_count || documentData[documentStatus.FOR_REVIEW].length || 0,
      [documentStatus.REVIEWING + "_count"]:
        in_review_count || documentData[documentStatus.REVIEWING].length || 0,
      completed_count:
        completed_count || documentData[documentStatus.COMPLETED].length || 0,
    }));
  }, [
    result,
    search_string,
    creator_user_id,
    collection_id,
    access_level,
    documents,
  ]);
};

/**
 * useAddDocument - Adds a new document to a column
 *
 */
export const useAddDocument = (result, document, column) => {
  const { setDocumentData } = useDocumentContext();

  useEffect(() => {
    if (!result || !result.success || !document || !column) return;

    setDocumentData((state) => ({
      ...state,
      [column]: [{ ...document, status: column }, ...state[column]],
    }));
  }, [result]);
};

/**
 * useAddMultipleDocsToQueue - Adds multiple documents to queue list
 *
 */
export const useAddMultipleDocsToQueue = (result, docRef, reqId) => {
  const { setDocumentData } = useDocumentContext();

  useEffect(() => {
    const { [reqId]: doc, ...docs } = docRef.current;
    docRef.current = docs;
    if (!result || !result.success || !doc) return;

    setDocumentData((state) => ({
      ...state,
      [QUEUE]: [doc, ...state[QUEUE]],
    }));
  }, [result, reqId]);
};
/**
 * useAddMultipleDocs - Adds multiple documents to queue list
 *
 */
export const useAddMultipleDocs = () => {
  const { setDocumentData } = useDocumentContext();

  return (result, document, column) => {
    if (!result || !result.success || !document || !column) return;

    setDocumentData((state) => ({
      ...state,
      [column]: [{ ...document, status: column }, ...state[column]],
    }));
  };
};

/**
 * useRemoveDocument - Removes a document from specified column
 *
 */
export const useRemoveDocument = (result, documentId, column) => {
  const { setDocumentData } = useDocumentContext();

  useEffect(() => {
    if (!result || !result.success || !documentId || !column) return;

    setDocumentData((state) => ({
      ...state,
      [column]: state[column].filter(
        ({ document_id }) => document_id !== documentId
      ),
    }));
  }, [result]);
};

/**
 * useRetryQueue - Help to show retry document report on queue
 */
export const useRetryQueue = (result, documentIndex) => {
  const { setDocumentData } = useDocumentContext();

  useEffect(() => {
    if (!result || !result.success || !documentIndex) return;

    setDocumentData((state) => {
      const queueList = _.cloneDeep(state[QUEUE]);
      queueList[documentIndex].status = QUEUE;
      return {
        ...state,
        [QUEUE]: queueList,
      };
    });
  }, [result]);
};
