import { Collapse } from "antd";
import { FolderOpenFilled, FolderFilled } from "@ant-design/icons";
import EntityGroups from "./EntityGroups";
import { entityStatus, entityStatusProps } from "../../../../constants";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import {
  handleEntitiesSelection,
  mergeEntityTypeIds,
} from "../../../../utils/helpers/analysis/entities";
import { useAnalysisContext } from "../../../../contexts/AnalysisContext";
import SkeletonLoader from "../../../common/loaders/SkeletonLoader";
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";

const { WAITING, ACCEPTED, REJECTED } = entityStatus;
const defaultSelections = {
  [WAITING]: [],
  [ACCEPTED]: [],
  [REJECTED]: [],
};

const CollapseHeader = ({ title, count, loading }) => (
  <div className="">
    {title} {!loading && <span className="CollapseHeader-count">{count}</span>}
  </div>
);

const EntityGroupsPane = () => {
  const { entityGroups } = useSelector(({ analysis }) => analysis);
  const { initialLoading } = useAnalysisContext();

  const entitiesIds = {
    [WAITING]: mergeEntityTypeIds(entityGroups[WAITING]),
    [ACCEPTED]: mergeEntityTypeIds(entityGroups[ACCEPTED]),
    [REJECTED]: mergeEntityTypeIds(entityGroups[REJECTED]),
  };

  const [selections, setSelections] = useState(defaultSelections);

  useEffect(() => {
    setSelections(defaultSelections);
  }, [entityGroups]);

  const handleSelection = handleEntitiesSelection(entityGroups, setSelections);

  return (
    <OverlayScrollbarsComponent
      options={{ scrollbars: { autoHide: "l", autoHideDelay: 200 } }}
      className="analysis-groups"
    >
      <Collapse
        defaultActiveKey={[WAITING, ACCEPTED, REJECTED]}
        expandIcon={({ isActive }) => (
          <div style={{}}>
            {isActive ? <IoIosArrowUp /> : <IoIosArrowDown />}
          </div>
        )}
        expandIconPosition="right"
        className="analysis-groups-collapse"
      >
        <Collapse.Panel
          key={WAITING}
          header={
            <CollapseHeader
              title={entityStatusProps[WAITING].label}
              count={entitiesIds[WAITING].length}
              loading={initialLoading}
            />
          }
          className="collapsehead"
        >
          <SkeletonLoader.EntityGroups
            loading={initialLoading}
            repeat={entityStatusProps[WAITING].loaderRepeat}
          />
          {!initialLoading && (
            <EntityGroups
              groupStatus={WAITING}
              selection={selections[WAITING]}
              handleSelection={handleSelection}
              totalEntities={entitiesIds[WAITING].length}
            />
          )}
        </Collapse.Panel>
        <Collapse.Panel
          key={ACCEPTED}
          header={
            <CollapseHeader
              title={entityStatusProps[ACCEPTED].label}
              count={entitiesIds[ACCEPTED].length}
              loading={initialLoading}
            />
          }
          className="collapsehead"
        >
          <SkeletonLoader.EntityGroups
            loading={initialLoading}
            repeat={entityStatusProps[ACCEPTED].loaderRepeat}
          />
          {!initialLoading && (
            <EntityGroups
              groupStatus={ACCEPTED}
              selection={selections[ACCEPTED]}
              handleSelection={handleSelection}
              totalEntities={entitiesIds[ACCEPTED].length}
            />
          )}
        </Collapse.Panel>
        <Collapse.Panel
          key={REJECTED}
          header={
            <CollapseHeader
              title={entityStatusProps[REJECTED].label}
              count={entitiesIds[REJECTED].length}
              loading={initialLoading}
            />
          }
          className="collapsehead"
        >
          <SkeletonLoader.EntityGroups
            loading={initialLoading}
            repeat={entityStatusProps[REJECTED].loaderRepeat}
          />
          {!initialLoading && (
            <EntityGroups
              groupStatus={REJECTED}
              selection={selections[REJECTED]}
              handleSelection={handleSelection}
              totalEntities={entitiesIds[REJECTED].length}
            />
          )}
        </Collapse.Panel>
      </Collapse>
    </OverlayScrollbarsComponent>
  );
};

export default EntityGroupsPane;
