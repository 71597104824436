import { Tag, Button } from "antd";
import { RadarChartOutlined } from "@ant-design/icons";
import { documentStatus } from "../../../../constants";
import api from "../../../../constants/api";
import useAxios from "../../../../hooks/useAxios";
import { useReloadAnalysis } from "../../../../hooks/useReload";
import { handleDocStatusUpdate } from "../../../../utils/helpers";
import infoMessages from "../../../../utils/messages/infoMessages";
import NavButton from "../../../common/widgets/NavButton";
import routes from "../../../../constants/routes";
import { useMessageBoxError } from "../../../../hooks/useMessageBox";

const { PUT } = api.methods;
const { COMPLETED } = documentStatus;

const CompleteTagWidget = ({ docId, isCompleted }) => {
  const [{ result, error }, updateDocumentRequest] = useAxios(null, PUT);

  useReloadAnalysis(result);
  useMessageBoxError(error);

  const graphUrl = routes.STIX_GRAPH.replace(":documentId", docId);

  if (isCompleted)
    return (
      <>
        <Tag key="complete-tag" color="green">
          {infoMessages._completed}
        </Tag>
        <NavButton
          url={graphUrl}
          text="Graph"
          icon={<RadarChartOutlined />}
          buttonProps={{
            shape: undefined,
            className: "title-btn mb-2",
          }}
        />
      </>
    );

  return (
    <Button
      key="mark-completed-btn"
      className="title-btn mark-completed-btn"
      size="small"
      type="primary"
      onClick={() =>
        handleDocStatusUpdate(docId, COMPLETED, "", updateDocumentRequest)
      }
    >
      {infoMessages.mark_complete}
    </Button>
  );
};

export default CompleteTagWidget;
