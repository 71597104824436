import React, { useEffect, useState } from "react";
import { ReactComponent as ReportIcon } from "../../../assets/svgs/entities/Report.svg";
import { ReactComponent as Performance } from "../../../assets/svgs/entities/performance.svg";
import { ReactComponent as Icon1 } from "../../../assets/svgs/entities/Icon_1.svg";

import { ReactComponent as Users } from "../../../assets/svgs/entities/users.svg";
import { ReactComponent as Slider } from "../../../assets/svgs/entities/sliders.svg";
import { ReactComponent as Metadata } from "../../../assets/svgs/entities/metadata.svg";
import { useAuthContext } from "../../../contexts/AuthContext";
import { useHistory, useLocation } from "react-router";
import { performanceDashboardSupported } from "../../../utils/helpers";
import routes from "../../../constants/routes";
import { appRoles } from "../../../constants";
import Sider from "antd/lib/layout/Sider";
import { Link } from "react-router-dom";
import readLogo from "../../../assets/svgs/entities/READ.svg";
import { Divider, Menu } from "antd";
import chevronRight from "../../../assets/svgs/entities/chevronRight.svg";
import chevronLeft from "../../../assets/svgs/entities/chevronLeft.svg";

function getItem(label, key, icon, onClick, children) {
  return {
    key,
    icon,
    label,
    onClick,
    children,
  };
}

const useLeftSidebar = () => {
  const history = useHistory();
  const { auth } = useAuthContext();
  const userRoles = auth && auth.realmAccess ? auth.realmAccess.roles : [];

  const items = [
    getItem("Reports", "1", <ReportIcon className="sidebar-icon" />, () =>
      history.push(routes.DOCUMENTS)
    ),
    performanceDashboardSupported() &&
      getItem(
        "Performance",
        "2",
        <Performance className="sidebar-icon" />,
        () => history.push(routes.PERFORMANCE_DASHBOARD)
      ),
    userRoles.includes(appRoles.MANAGE_ORG_COLLECTIONS) &&
      getItem("Buckets", "3", <Icon1 className="sidebar-icon" />, () =>
        history.push(routes.COLLECTIONS)
      ),
    getItem("Rules", "4", <Slider className="sidebar-icon" />, () =>
      history.push(routes.RULES_MANAGER)
    ),
    userRoles.includes(appRoles.MANAGE_ORG_SETTINGS) &&
      userRoles.includes(appRoles.MANAGE_ORG_SETTINGS) &&
      getItem("Organisation", "5", <Users className="sidebar-icon" />, () =>
        history.push(routes.ORGANISATION)
      ),
    userRoles.includes(appRoles.ORGANISATION_METADATA) &&
      getItem("Metadata", "6", <Metadata className="sidebar-icon" />, () =>
        history.push(routes.METADATA)
      ),
  ].filter(Boolean);

  return items;
};

const LeftSidebar = () => {
  const [collapsed, setCollapsed] = useState(false);
  const LeftSidebar = useLeftSidebar();
  const history = useHistory();
  const location = useLocation();
  const [selectedKey, setSelectedKey] = useState(-1);

  useEffect(() => {
    switch (`${location.pathname}`) {
      case routes.DOCUMENTS:
        setSelectedKey("1");
        break;
      case routes.PERFORMANCE_DASHBOARD:
        setSelectedKey("2");
        break;
      case routes.COLLECTIONS:
        setSelectedKey("3");
        break;
      case routes.RULES_MANAGER:
        setSelectedKey("4");
        break;
      case routes.ORGANISATION:
        setSelectedKey("5");
        break;
      case routes.METADATA:
        setSelectedKey("6");
        break;
      default:
        setSelectedKey("-1");
    }
  }, [location.pathname]);

  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };

  return (
    <Sider
      collapsed={collapsed}
      trigger={null}
      className="slider-mainHead"
      width={256}
    >
      <div className="app-read-logo slider-mainHead-logo">
        {!collapsed && (
          <Link to={routes.HOME}>
            <img src={readLogo} alt="READ" width="72px" />
          </Link>
        )}
        {!collapsed ? (
          <img
            src={chevronRight}
            alt="right-arrow"
            onClick={toggleCollapsed}
            className="slider-mainHead-MdArrowBackIos"
          />
        ) : (
          <img
            src={chevronLeft}
            alt="left-arrow"
            onClick={toggleCollapsed}
            className="slider-mainHead-MdArrowForwardIos"
          />
        )}
      </div>
      <Divider className="slider-mainHead-Divider" />
      <Menu
        theme="dark"
        selectedKeys={[selectedKey]}
        mode="inline"
        className="slider-mainHead-menu"
      >
        {LeftSidebar.map((item) => (
          <Menu.Item
            key={item.key}
            icon={item.icon}
            onClick={() => {
              item.onClick();
              setSelectedKey(item.key);
              item.onClick();
              setSelectedKey(item.key);
            }}
            className="slider-mainHead-menu-item"
          >
            {!collapsed && item.label}
          </Menu.Item>
        ))}
      </Menu>
    </Sider>
  );
};

export default LeftSidebar;
