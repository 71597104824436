import { useSelector } from "react-redux";
import { blockType } from "../../../../constants";
import AppPopover from "../../../common/widgets/AppPopover";
import ChooseEntity from "./ChooseEntity";
import EntityMenu from "./EntityMenu";

/**
 * Popup options on click or text selection
 *
 * @function
 * @param {{any}} children - A react node
 * @param {{object}} block - The current block
 * @returns React JSX
 */
const ContextOptions = ({ children, block }) => {
  const { activeSelection } = useSelector(({ selection }) => selection);
  const { selectedTextEntity, selectedSentenceEntity, isClick, blockId } =
    activeSelection;

  const isTable = blockId.split("-")[0] === blockType.TABLE;
  if (isTable && isClick && !(selectedTextEntity || selectedSentenceEntity))
    return children;

  const isMultiline = !!activeSelection.multiline;

  let entity_ids = [];
  if (selectedTextEntity) entity_ids.push(selectedTextEntity.id);
  if (selectedSentenceEntity)
    entity_ids = [...entity_ids, ...selectedSentenceEntity.ids];

  return (
    <AppPopover
      trigger="click"
      content={
        entity_ids.length > 1 && activeSelection.isClick ? (
          <ChooseEntity
            activeSelection={activeSelection}
            entity_ids={entity_ids}
          />
        ) : (
          <EntityMenu
            activeSelection={activeSelection}
            block={block}
            isTable={isTable}
            isMultiline={isMultiline}
          />
        )
      }
      defaultVisible={true}
      destroyTooltipOnHide={true}
    >
      {children}
    </AppPopover>
  );
};

export default ContextOptions;
