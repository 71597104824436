import _ from "lodash";
import {
  HIDE_ALL_RELATIONSHIPS,
  SHOW_ALL_RELATIONSHIPS,
} from "../../constants";
import { mapEntityRelations } from "../../utils/helpers/analysis/relationship";

export const SET_ANALYSIS = "SET_ANALYSIS";
export const SET_ANNOTATION_RELATIONS = "SET_ANNOTATION_RELATIONS";
export const RESET_ANALYIS_STATE = "RESET_ANALYIS_STATE";
export const SET_ANALYSIS_MODE = "SET_ANALYSIS_MODE";

export const ADD_SELECTION = "ADD_SELECTION";
export const REMOVE_SELECTION = "REMOVE_SELECTION";
export const SHOW_SLIDE_IN_VIEW = "SHOW_SLIDE_IN_VIEW";
export const HIDE_SLIDE_IN_VIEW = "HIDE_SLIDE_IN_VIEW";
export const REMOVE_SLIDE_IN_VIEW = "REMOVE_SLIDE_IN_VIEW";
export const SHOW_MODAL_VIEW = "SHOW_MODAL_VIEW";
export const REMOVE_MODAL_VIEW = "REMOVE_MODAL_VIEW";
export const RESET_SELECTION_STATE = "RESET_SELECTION_STATE";
export const SET_PROCESSING_ENTITIES = "SET_PROCESSING_ENTITIES";
export const ADD_PROCESSING_ENTITIES = "ADD_PROCESSING_ENTITIES";
export const SET_VIRTUAL_ENTITIES = "SET_VIRTUAL_ENTITIES";
export const ADD_VIRTUAL_ENTITIES = "ADD_VIRTUAL_ENTITIES";
export const ADD_API_REQUEST_ID = "ADD_API_REQUEST_ID";
export const SET_API_REQUEST_IDS = "SET_API_REQUEST_IDS";

export const SET_RELATIONSHIPS = "SET_RELATIONSHIPS";
export const RELATIONSHIP_NODES = "RELATIONSHIP_NODES";
export const RELATIONSHIP_VISIBILITY = "RELATIONSHIP_VISIBILITY";
export const NEW_RELATIONSHIP_STARTED = "NEW_RELATIONSHIP_STARTED";
export const NEW_RELATIONSHIP_RESET = "NEW_RELATIONSHIP_RESET";
export const NEW_RELATIONSHIP_ROLE = "NEW_RELATIONSHIP_ROLE";
export const RELATIONSHIP_PROPS = "RELATIONSHIP_PROPS";
export const SHOULD_NOT_DRAW_LINE = "SHOULD_NOT_DRAW_LINE";
export const SHOULD_DRAW_LINE = "SHOULD_DRAW_LINE";
export const TRACK_HIDDEN_RELATIONSHIPS = "TRACK_HIDDEN_RELATIONSHIPS";
export const RESET_RELATIONSHIP_STATE = "RESET_RELATIONSHIP_STATE";

export const SET_USER_DETAILS = "SET_USER_DETAILS";
export const MERGE_USER_DETAILS = "MERGE_USER_DETAILS";

export const START_LOADING = "START_LOADING";
export const STOP_LOADING = "STOP_LOADING";
export const ADD_LOADER = "ADD_LOADER";
export const REMOVE_LOADER = "REMOVE_LOADER";
export const RESET_LOADER_STATE = "RESET_LOADER_STATE";

/* Action creators */

/* Updates analysis state with data from api call */
export const setAnalysis = (data) => {
  const {
    document_id: id,
    source,
    title,
    status,
    tables,
    blocks,
    entities_by_ids,
    entity_groups,
    block_groups,
    table_cells_entities,
    entity_props,
    has_stix_bundle,
    source_type,
    metadata_profile_id,
    has_extraction_time,
    full_summary,
  } = data;

  const payload = {
    document: {
      id,
      source,
      title,
      status,
      sourceType: source_type,
      hasStixBundle: has_stix_bundle,
      metadataProfileId: metadata_profile_id || null,
      hasExtractionTime: has_extraction_time,
    },
    blocks,
    tables,
    entitiesByIds: entities_by_ids,
    tableEntitiesGroups: table_cells_entities,
    entityGroups: entity_groups,
    blockGroups: block_groups,
    entityProps: entity_props,
    hasStixBundle: has_stix_bundle,
    fullSummary: full_summary,
  };

  return { type: SET_ANALYSIS, payload };
};

/* Set analysis mode: Annotation or Graph */
export const setAnalysisMode = (mode) => {
  return { type: SET_ANALYSIS_MODE, payload: mode };
};

/* Updates relations and props in relationship state */
export const setRelationships = (relations) => {
  const payload = {
    relations,
    entityRelations: mapEntityRelations(relations),
    countVisible: relations.length,
  };

  return { type: SET_RELATIONSHIPS, payload };
};

export const shouldNotDrawLines = (noDraw = true) => {
  return { type: noDraw ? SHOULD_NOT_DRAW_LINE : SHOULD_DRAW_LINE };
};

/* Set relationships props */
export const setRelationshipProps = (props) => {
  return { type: RELATIONSHIP_PROPS, payload: props };
};

/* Show all relationships  */
export const showAllRelationships = () => {
  const payload = { visibility: SHOW_ALL_RELATIONSHIPS };
  return { type: SHOW_ALL_RELATIONSHIPS, payload };
};

/* Hide all relationships  */
export const hideAllRelationships = () => {
  const payload = { visibility: HIDE_ALL_RELATIONSHIPS };
  return { type: HIDE_ALL_RELATIONSHIPS, payload };
};

/* Update state to track hidden relationships  */
export const trackHiddenRelationships = (hidden) => {
  return { type: TRACK_HIDDEN_RELATIONSHIPS, payload: hidden };
};

/* Updates relationship visibility state */
export const setRelationshipsVisibility = (visibility, rProps) => {
  const payload = { visibility };

  if ([SHOW_ALL_RELATIONSHIPS, HIDE_ALL_RELATIONSHIPS].includes(visibility)) {
    const visible = visibility === SHOW_ALL_RELATIONSHIPS;
    const props = _.cloneDeep(rProps);
    for (let id in props) props[id]["visible"] = visible;
    payload["props"] = props;
  }

  return { type: RELATIONSHIP_VISIBILITY, payload };
};

/* Updates selection state */
export const setSelection = (props = null) => {
  if (props) {
    return { type: ADD_SELECTION, payload: props };
  } else {
    return { type: REMOVE_SELECTION };
  }
};

/* Remove selection state */
export const removeActiveSelection = () => ({ type: REMOVE_SELECTION });

/* Show slidein view */
export const showSlideInView = (ContentNode, props = {}, slideInProps = {}) => {
  return {
    type: SHOW_SLIDE_IN_VIEW,
    payload: { show: true, ContentNode, props, slideInProps },
  };
};

/* Hide slidein view */
export const hideSlideInView = () => ({ type: HIDE_SLIDE_IN_VIEW });

/* Remove slidein view */
export const removeSlideInView = () => ({ type: REMOVE_SLIDE_IN_VIEW });

/* Show modal view */
export const showModalView = (ContentNode, props = {}, modalProps = {}) => {
  return {
    type: SHOW_MODAL_VIEW,
    payload: { show: true, ContentNode, props, modalProps },
  };
};

/* Remove modal view */
export const removeModalView = () => ({ type: REMOVE_MODAL_VIEW });

/* Set values for entities that are being processed */
export const setProcessingEntities = (entityIds) => ({
  type: SET_PROCESSING_ENTITIES,
  payload: entityIds,
});

/* Add values for entities that are being processed */
export const addProcessingEntities = (entityIds) => ({
  type: ADD_PROCESSING_ENTITIES,
  payload: entityIds,
});

/* Set values for virtual entities */
export const setVirtualEntities = (virtualEntities) => ({
  type: SET_VIRTUAL_ENTITIES,
  payload: virtualEntities,
});

/* Add generated api request id to tracked items */
export const addApiRequestId = (payload) => ({
  type: ADD_API_REQUEST_ID,
  payload,
});

/* Set generated api request ids */
export const setApiRequestIds = (payload) => ({
  type: SET_API_REQUEST_IDS,
  payload,
});

/* Start a new relationship proccess */
export const startNewRelationship = (payload) => ({
  type: NEW_RELATIONSHIP_STARTED,
  payload,
});

/* Set new relationship role */
export const setNewRelationshipRole = (role) => ({
  type: NEW_RELATIONSHIP_ROLE,
  payload: role,
});

/* Increment page loader */
export const addPageLoader = () => ({ type: ADD_LOADER });

/* Decrement page loader */
export const removePageLoader = () => ({ type: REMOVE_LOADER });
