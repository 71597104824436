import { Fragment } from "react";
import { useSelector } from "react-redux";
import _ from "lodash";
import {
  HIGHLIGTHED_PLAIN_NODE,
  WRAPPER_PARENT,
  blockType,
} from "../../../constants";
import HTMLConstruct from "./HTMLConstruct";

const headers = new Set(["h1", "h2", "h3", "h4", "h5", "h6"]);

const HTMLGroupConstruct = ({ blockGroup }) => {
  const { analysis, selection } = useSelector((state) => state);

  let sel = selection.activeSelection || {};
  const multilineById = sel.multilineById || {};

  const { tag, indices } = blockGroup;

  return (
    <>
      {indices.map((blockIndex, key) => {
        const block = _.cloneDeep(analysis.blocks[blockIndex]);
        const blockId = `${blockType.SENTENCE}-${block.sentence_idx}-${blockIndex}`;

        const seln = sel.blockId === blockId ? sel : null;
        const highlighted = [];

        if (!seln && multilineById[blockId]) {
          const d = multilineById[blockId];

          highlighted.push({
            type: HIGHLIGTHED_PLAIN_NODE,
            text: block.text.slice(d.start_position, d.end_position),
            start_point: d.start_position,
            end_point: d.end_position,
          });
        }

        if (tag === "p") {
          return (
            <Fragment key={key}>
              <span id={blockId} data-type={WRAPPER_PARENT}>
                <HTMLConstruct
                  block={block}
                  activeSelection={seln}
                  highlighted={highlighted}
                />
              </span>{" "}
            </Fragment>
          );
        } else if (tag === "quote") {
          return (
            <Fragment key={key}>
              <em id={blockId} data-type={WRAPPER_PARENT}>
                <HTMLConstruct
                  block={block}
                  activeSelection={seln}
                  highlighted={highlighted}
                />
              </em>
            </Fragment>
          );
        } else if (tag === "ul" || tag === "ol" || tag === "li") {
          return (
            <li key={key} id={blockId} data-type={WRAPPER_PARENT}>
              <HTMLConstruct
                block={block}
                activeSelection={seln}
                highlighted={highlighted}
              />
            </li>
          );
        } else if (headers.has(tag)) {
          return (
            <span key={key} id={blockId} data-type={WRAPPER_PARENT}>
              <HTMLConstruct
                block={block}
                activeSelection={seln}
                highlighted={highlighted}
              />
            </span>
          );
        } else if (tag === "img") {
          return (
            <img key={key} src={block.src} className="analysis-image" alt="" />
          );
        }

        // Default to paragraph group
        return (
          <Fragment key={key}>
            <span id={blockId} data-type={WRAPPER_PARENT}>
              <HTMLConstruct
                block={block}
                activeSelection={seln}
                highlighted={highlighted}
              />
            </span>{" "}
          </Fragment>
        );
      })}
    </>
  );
};

export default HTMLGroupConstruct;
